import React from "react";
import { wording } from "../DataForForm/Wording";
import FormBlock from "../Default/FormBlock";

function DrugProblemBlock({ parentCallback, appProOrNotpro, selectedValue }) {
  const grandPublic = wording.grand_public.drug_problem;
  const pro = wording.pro.drug_problem;
  const items = appProOrNotpro !== "Patient" ? pro : grandPublic;

  return (
    <FormBlock
      parentCallback={parentCallback}
      selectedValue={selectedValue}
      items={items}
    ></FormBlock>
  );
}
export default DrugProblemBlock;
