import {
  SET_APP_TPA_ID,
  HOME_CLICK_NEXT_BTN,
  HOME_CLICK_PREV_BTN,
  SET_PVI_TYPE,
  SET_APP_PRO_OR_NOPRO,
} from "./actionTypes";
import { setPvAppProOrNotPro } from "./pvActions";
import store from "./../../store";
export function setAppProOrNotPro(value) {
  store.dispatch(setPvAppProOrNotPro(value));
  return {
    type: SET_APP_PRO_OR_NOPRO,
    payload: { value },
  };
}

/**
 * set app_tpa_id
 * @param {*} value
 */
export function setAppTpaId(value) {
  return {
    type: SET_APP_TPA_ID,
    payload: { value },
  };
}

/**
 * set pvi_type
 * @param {*} value
 */
export function setPviType(value) {
  return {
    type: SET_PVI_TYPE,
    payload: { value },
  };
}

/**
 * set page index value
 * @param {*} value
 */
export function clickNextButton(pageIndex) {
  return {
    type: HOME_CLICK_NEXT_BTN,
    payload: { pageIndex },
  };
}

/**
 * set page index value
 * @param {*} pageIndex
 */
export function clickPrevButton(pageIndex) {
  return {
    type: HOME_CLICK_PREV_BTN,
    payload: { pageIndex },
  };
}
