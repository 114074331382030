import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Image } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import Letter from "../../assets/img/letter2.png";
import ValidateEmailModal from "../Modal/ValidateEmailModal";

function ContactBlock({
  appTpaId,
  parentCallback,
  contact,
  clickPrevButton,
  setPrevButtonVisible,
  pageIndex,
  sendFormDataButton,
  formData,
  pviType,
}) {
  // HOOK FORM
  const { register, handleSubmit, errors } = useForm();
  const [showModal, setShowModal] = useState(false);
  let title;
  if (pviType === "AQ") {
    title = "Réclamation qualité";
  }
  if (pviType === "PV") {
    title = "Pharmacovigilance";
  }
  if (pviType === "IM") {
    title = "Information";
  }
  const onSubmit = (data) => {
    parentCallback(data);
    setShowModal(true);
  };

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right">
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <>
      <Row className="justify-content-center">
        <Col xs="11" sm="11">
          <p className="question-label row-no-margin text-center">
            Vos coordonnées
          </p>
        </Col>
        <Col xs="10" md="4">
          <Image src={Letter} width="100%"></Image>
        </Col>
        <Col xs="10" md="5">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            {/* if its not AQ , the name and last name are not required */}
            {pviType !== "AQ" ? (
              <Row>
                <Col md="6">
                  <label className="contact-form__label">Nom</label>
                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    name="app_nom"
                    defaultValue={contact.app_nom}
                    ref={register({ required: false, maxLength: 200 })}
                  />
                  {/* errors will return when field validation fails  */}
                  {errors?.app_nom?.type === "maxLength" && (
                    <span className="mandatory-field">
                      La taille ne doit pas dépasser 200 caractères
                    </span>
                  )}
                </Col>
                <Col md="6">
                  <label className="contact-form__label">Prénom</label>
                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    name="app_prenom"
                    defaultValue={contact.app_prenom}
                    ref={register({ required: false, maxLength: 100 })}
                  />
                  {/* errors will return when field validation fails  */}
                  {errors?.app_prenom?.type === "maxLength" && (
                    <span className="mandatory-field">
                      La taille ne doit pas dépasser 200 caractères
                    </span>
                  )}
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md="6">
                  <label className="contact-form__label">Nom *</label>
                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    name="app_nom"
                    defaultValue={contact.app_nom}
                    ref={register({ required: true, maxLength: 200 })}
                  />
                  {/* errors will return when field validation fails  */}
                  {errors?.app_nom && (
                    <span className="mandatory-field">
                      Ce champ est obligatoire
                    </span>
                  )}
                  {errors?.app_nom?.type === "maxLength" && (
                    <span className="mandatory-field">
                      La taille ne doit pas dépasser 200 caractères
                    </span>
                  )}
                </Col>
                <Col md="6">
                  <label className="contact-form__label">Prénom *</label>
                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    name="app_prenom"
                    defaultValue={contact.app_prenom}
                    ref={register({ required: true, maxLength: 100 })}
                  />
                  {/* errors will return when field validation fails  */}{" "}
                  {errors?.app_prenom && (
                    <span className="mandatory-field">
                      Ce champ est obligatoire
                    </span>
                  )}
                  {errors?.app_prenom?.type === "required" && (
                    <span className="mandatory-field">
                      La taille ne doit pas dépasser 100 caractères
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {appTpaId === "Officine" && (
              <React.Fragment>
                <label className="contact-form__label">
                  Nom de la pharmacie
                </label>
                <input
                  autoComplete="off"
                  className="contact-form__field"
                  name="app_etablissement"
                  defaultValue={contact.app_etablissement}
                  ref={register({ required: false, maxLength: 200 })}
                />
                {/* errors will return when field validation fails  */}
                {errors?.app_etablissement?.type === "maxLength" && (
                  <span className="mandatory-field">
                    La taille ne doit pas dépasser 200 caractères
                  </span>
                )}
              </React.Fragment>
            )}
            {appTpaId === "Médecin" && (
              <React.Fragment>
                <label className="contact-form__label">Spécialité</label>
                <input
                  autoComplete="off"
                  className="contact-form__field"
                  name="app_specialite"
                  defaultValue={contact.app_specialite}
                  ref={register({ required: false, maxLength: 200 })}
                />
                {/* errors will return when field validation fails  */}
                {errors?.app_specialite?.type === "maxLength" && (
                  <span className="mandatory-field">
                    La taille ne doit pas dépasser 200 caractères
                  </span>
                )}
              </React.Fragment>
            )}
            <label className="contact-form__label">Adresse</label>
            <input
              autoComplete="off"
              className="contact-form__field"
              name="app_adresse"
              defaultValue={contact.app_adresse}
              ref={register({ required: false, maxLength: 200 })}
            />
            {/* errors will return when field validation fails  */}
            {errors?.app_adresse?.type === "maxLength" && (
              <span className="mandatory-field">
                La taille ne doit pas dépasser 200 caractères
              </span>
            )}
            <label className="contact-form__label">Code postal</label>
            <input
              autoComplete="off"
              className="contact-form__field"
              name="app_code_postal"
              type="number"
              defaultValue={contact.app_code_postal}
              ref={register({ required: false, maxLength: 5 })}
            />
            {/* errors will return when field validation fails  */}
            {errors?.app_code_postal?.type === "maxLength" && (
              <span className="mandatory-field">
                La taille ne doit pas dépasser 5 caractères
              </span>
            )}
            <label className="contact-form__label">Ville</label>
            <input
              autoComplete="off"
              className="contact-form__field"
              name="app_ville"
              defaultValue={contact.app_ville}
              ref={register({ required: false, maxLength: 200 })}
            />
            {/* errors will return when field validation fails  */}
            {errors?.app_ville?.type === "maxLength" && (
              <span className="mandatory-field">
                La taille ne doit pas dépasser 200 caractères
              </span>
            )}
            <label className="contact-form__label">Pays</label>
            <input
              autoComplete="off"
              className="contact-form__field"
              name="app_pays"
              defaultValue={contact.app_pays}
              ref={register({ required: false, maxLength: 200 })}
            />
            {/* errors will return when field validation fails  */}
            {errors?.app_pays?.type === "maxLength" && (
              <span className="mandatory-field">
                La taille ne doit pas dépasser 200 caractères
              </span>
            )}
            <label className="contact-form__label">Numéro de téléphone</label>
            <input
              autoComplete="off"
              className="contact-form__field"
              name="app_telephone1"
              type="number"
              maxLength="10"
              defaultValue={contact.app_telephone1}
              ref={register({ required: false, maxLength: 10 })}
            />
            {errors?.app_telephone1?.type === "maxLength" && (
              <span className="mandatory-field">
                La taille ne doit pas dépasser 10 caractères
              </span>
            )}
            <label className="contact-form__label">Email *</label>
            <input
              autoComplete="off"
              className="contact-form__field"
              name="app_email"
              defaultValue={contact.app_email}
              type="email"
              ref={register({ required: true, maxLength: 200 })}
            />
            {/* errors will return when field validation fails  */}
            {errors?.app_email && (
              <span className="mandatory-field">Ce champ est obligatoire</span>
            )}
            {errors?.app_email?.type === "maxLength" && (
              <span className="mandatory-field">
                La taille ne doit pas dépasser 200 caractères
              </span>
            )}

            {/* Only desktop*/}
            <div className="d-none d-sm-block">
              <Row className="justify-content-start pt-2 mt-2 ">
                <Col sm="6">
                  <Row className="justify-content-end"> {prevButton}</Row>
                </Col>
                <Col sm="5">
                  <Row className="justify-content-start ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* Only mobile*/}
            <div className="d-sm-none d-block">
              <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
                <div className="d-block ml-5 mr-5">{prevButton}</div>
                <button
                  className="no-style-button d-block ml-5 mr-5 mt-3"
                  type="submit"
                >
                  {nextButton}
                </button>
              </Row>
            </div>
          </form>
        </Col>
        {showModal && (
          <ValidateEmailModal
            setShowModal={setShowModal}
            email={contact.app_email}
            setPrevButtonVisible={setPrevButtonVisible}
            sendFormDataButton={sendFormDataButton}
            pageIndex={pageIndex}
            formData={formData}
            title={title}
          ></ValidateEmailModal>
        )}
      </Row>      
    </>
  );
}

export default ContactBlock;
