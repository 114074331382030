import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import PrevButton from "./../components/button/PrevButton";
import NextButton from "./../components/button/NextButton";
import ContactBlock from "../components/Aq/ContactBlock";
import DrugDescriptionBlock from "../components/Aq/DrugDescriptionBlock";
import CommentBlock from "../components/Aq/CommentBlock";
import OriginBlock from "../components/Aq/OriginBlock";
import DetectionMomentBlock from "../components/Aq/DetectionMoment";
import ThanksBlock from "../components/Aq/ThanksBlock";
import BackendErrorBlock from "../components/Aq/BackendErrorBlock";
import history from "./../history";
import {
  clickNextButton,
  clickPrevButton,
  setAppIdentity,
  setPviDenomination,
  setPviRqpReclamation,
  setPviRqpReclamation2,
  setPviRqpReclamation5,
  setPviRqpDecouvert,
  setPviRqpQuand,
  sendFormAQ,
} from "./../redux/actions/aqActions";
import DrugOrBoxProblemBlock from "../components/Aq/DrugOrBoxProblemBlock";
import BoxProblemBlock from "../components/Aq/BoxProblemBlock";
import BoxContentProblemBlock from "../components/Aq/BoxContentProblemBlock";
import DrugProblemBlock from "../components/Aq/DrugProblemBlock";
import Stepper from "react-js-stepper";

const steps = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

class AqContainer extends Component {
  componentDidMount() {
    const { home } = this.props;
    if (home.pageIndex === 0 && !home.app_tpa_id && !home.pvi_type) {
      history.push("/");
    }
  }

  render() {
    const {
      aq,
      home,
      clickNextButton,
      clickPrevButton,
      setAppIdentity,
      setPviDenomination,
      setPviRqpReclamation,
      setPviRqpReclamation2,
      setPviRqpReclamation5,
      setPviRqpDecouvert,
      setPviRqpQuand,
      sendFormAQ,
    } = this.props;

    //components in home container page index 0-2
    //components in home container page index 3-12
    const components = {
      3: (
        <Fragment>
          <Row className="justify-content-center mt-2 mb-2 row-no-margin">
            <Col sm="12" xs="12">
              <h2 className="app-subtitle">
                Quel est le produit concerné par votre réclamation ?
              </h2>
            </Col>
          </Row>
          <Row>
            <DrugDescriptionBlock
              drugDescription={aq.pvi_denomination}
              parentCallback={setPviDenomination}
              clickNextButton={clickNextButton}
              clickPrevButton={clickPrevButton}
              pageIndex={aq.pageIndex}
              appProOrNotpro={home.app_pro_or_notpro}
            ></DrugDescriptionBlock>
          </Row>
        </Fragment>
      ),
      4: (
        <DrugOrBoxProblemBlock
          parentCallback={setPviRqpReclamation}
          selectedValue={aq.pvi_rqp_reclamation}
          appProOrNotpro={home.app_pro_or_notpro}
        ></DrugOrBoxProblemBlock>
      ),
      5: (
        <BoxProblemBlock
          parentCallback={setPviRqpReclamation2}
          selectedValue={aq.pvi_rqp_reclamation2}
          appProOrNotpro={home.app_pro_or_notpro}
        ></BoxProblemBlock>
      ),
      6: (
        <BoxContentProblemBlock
          parentCallback={setPviRqpReclamation2}
          selectedValue={aq.pvi_rqp_reclamation2}
          appProOrNotpro={home.app_pro_or_notpro}
        ></BoxContentProblemBlock>
      ),
      7: (
        <DrugProblemBlock
          parentCallback={setPviRqpReclamation2}
          selectedValue={aq.pvi_rqp_reclamation2}
          appProOrNotpro={home.app_pro_or_notpro}
        ></DrugProblemBlock>
      ),
      8: (
        <Row className="justify-content-center">
          <Col xs="11" sm="11">
            <p className="question-label row-no-margin">
              Pour une meilleure prise en charge, merci de décrire avec
              précision le défaut :
            </p>
          </Col>
          <Col sm="12">
            <CommentBlock
              comment={aq.pvi_rqp_reclamation5}
              parentCallback={setPviRqpReclamation5}
              pageIndex={aq.pageIndex}
              clickNextButton={clickNextButton}
              clickPrevButton={clickPrevButton}
              pviType={home.pvi_type}
            ></CommentBlock>
          </Col>
        </Row>
      ),
      9: (
        <Row className="justify-content-center">
          <Col xs="11" sm="11">
            <p className="question-label row-no-margin">
              Qui est à l’origine de la détection ?
            </p>
          </Col>
          <OriginBlock
            parentCallback={setPviRqpDecouvert}
            selectedValue={aq.pvi_rqp_decouvert}
            appProOrNotpro={home.app_pro_or_notpro}
          ></OriginBlock>
        </Row>
      ),
      10: (
        <Row className="justify-content-center">
          <Col xs="11" sm="11">
            <p className="question-label row-no-margin">
              A quel moment a été détectée l’anomalie ?
            </p>
          </Col>
          <DetectionMomentBlock
            parentCallback={setPviRqpQuand}
            selectedValue={aq.pvi_rqp_quand}
            appProOrNotpro={home.app_pro_or_notpro}
          ></DetectionMomentBlock>
        </Row>
      ),
      11: (
        <ContactBlock
          appTpaId={home.app_tpa_id}
          contact={aq.app_identity}
          parentCallback={setAppIdentity}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={aq.pageIndex}
          formData={{ aq, home }}
          sendFormDataButton={sendFormAQ}
          pviType={home.pvi_type}
        ></ContactBlock>
      ),
      12: <ThanksBlock></ThanksBlock>,
      13: <BackendErrorBlock formData={aq}></BackendErrorBlock>
    };

    //array for not display btn next or prev
    const pageIndexExculForBtn = [3, 8, 11, 12,13];

    // array for not display layout
    const showLayout = [3, 8, 9, 10, 11, 12];
    return (
      <Container fluid>
        <Stepper
          steps={steps}
          activeStep={aq.pageIndex - 2}
          showNumber={false}
        />
        {!showLayout.includes(aq.pageIndex) && (
          <Row className="justify-content-center mt-2 mb-2 row-no-margin">
            <Col sm="11" xs="11">
              <h2 className="app-subtitle">Le défaut concerne :</h2>
            </Col>
          </Row>
        )}

        {/* Slider controller by page index number */}
        {components[aq.pageIndex]}

        {/* Only desktop*/}
        {!pageIndexExculForBtn.includes(aq.pageIndex) && (
          <div className="d-none d-sm-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ">
              <Col sm="4" lg="3" className="mr-4 mt-2">
                <Row className="justify-content-end">
                  <PrevButton
                    isPrevButtonVisible={aq.isPrevButtonVisible}
                    clickPrevButton={clickPrevButton}
                    pageIndex={aq.pageIndex}
                  ></PrevButton>
                </Row>
              </Col>
              <Col sm="4" lg="3" className="ml-4 mt-2">
                <Row className="justify-content-start">
                  <NextButton
                    isNextButtonVisible={aq.isNextButtonVisible}
                    clickNextButton={clickNextButton}
                    pageIndex={aq.pageIndex}
                  ></NextButton>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        {/* Only mobile*/}
        {!pageIndexExculForBtn.includes(aq.pageIndex) && (
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5 mb-3">
                <PrevButton
                  isPrevButtonVisible={aq.isPrevButtonVisible}
                  clickPrevButton={clickPrevButton}
                  pageIndex={aq.pageIndex}
                ></PrevButton>
              </div>

              <NextButton
                isNextButtonVisible={aq.isNextButtonVisible}
                clickNextButton={clickNextButton}
                pageIndex={aq.pageIndex}
              ></NextButton>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  home: state.home,
  aq: state.aq,
});

export default connect(mapStateToProps, {
  clickNextButton,
  clickPrevButton,
  setAppIdentity,
  setPviDenomination,
  setPviRqpReclamation,
  setPviRqpReclamation2,
  setPviRqpReclamation5,
  setPviRqpDecouvert,
  setPviRqpQuand,
  sendFormAQ,
})(AqContainer);
