import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import { PVWording } from "../DataForForm/PVWording";
import AddButton from "../button/AddButton";
import RemoveButton from "../button/RemoveButton";
import Tooltips from "../Default/Tooltips";
function OngoingTreatment({
  ongoingTreatment,
  setOngoingTreatment,
  clickNextButton,
  clickPrevButton,
  pageIndex,
  appProOrNotpro,
}) {
  //HOOK FORM
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      ongoingTreatments: ongoingTreatment && ongoingTreatment.ongoingTreatments,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ongoingTreatments",
  });

  // Add the first form in the form array
  useEffect(() => {
    if (fields.length === 0) {
      append();
    }
  });

  const drugIndication =
    appProOrNotpro !== "Patient"
      ? PVWording.pro.ongoingTreatment.drugIndication
      : PVWording.grand_public.ongoingTreatment.drugIndication;

  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setOngoingTreatment(sendData);
    clickNextButton(pageIndex);
  };
  const voieAdministration = PVWording.pro.pvi_voie_administration;

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <>
      <Row className="justify-content-start  row-no-margin">
        <Col xs="11" sm="11">
          <p className="question-label">
            Quels sont les autres traitements en cours au moment de la survenue
            de l’effet indésirable?
          </p>
        </Col>
      </Row>
      <Row className=" row-no-margin">
        <Col xs="12" md="8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul>
              {fields.map((field, index) => (
                <div key={field.id}>
                  <Row>
                    <Col>
                      {index !== 0 && (
                        <RemoveButton
                          className="cancel-button"
                          clickRemoveButton={() => remove(index)}
                        ></RemoveButton>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                        Nom du produit
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_name`}
                        defaultValue={field.pvi_pv_drug_name}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                        Numéro de lot
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_number_lot`}
                        defaultValue={field.pvi_pv_drug_number_lot}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                      Date de péremption
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_peremption_date`}
                        defaultValue={field.pvi_pv_drug_peremption_date}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                        Voie d'administration
                      </label>
                      <select
                        className="contact-form__field"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_route_administration`}
                        defaultValue={field.pvi_pv_drug_route_administration}
                        ref={register({ required: false })}
                      >
                        <option value=""></option>
                        {voieAdministration.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                        {drugIndication}
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_indication`}
                        defaultValue={field.pvi_pv_drug_indication}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">Posologie</label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_posologie`}
                        defaultValue={field.pvi_pv_drug_posologie}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                        Date de début de la prise
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_start_date`}
                        defaultValue={field.pvi_pv_drug_start_date}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                        Date de fin de la prise
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10" sm="10" xs="10">
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_end_date`}
                        defaultValue={field.pvi_pv_drug_end_date}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                    <Col md="1" sm="1" xs="1">
                      <Tooltips
                        direction="left"
                        content="Si le traitement n’est pas terminé, indiquez EC
"
                      ></Tooltips>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">
                        Durée de prise du traitement
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name={`ongoingTreatments[${index}].pvi_pv_drug_treatment_duration`}
                        defaultValue={field.pvi_pv_drug_treatment_duration}
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <hr></hr>
                </div>
              ))}
            </ul>
            <AddButton
              clickAddButton={append}
              label="Ajouter un autre traitement"
            ></AddButton>
            {/* Only desktop*/}
            <div className="d-none d-sm-block">
              <Row className="justify-content-start pt-2 mt-2 ">
                <Col sm="6">
                  <Row className="justify-content-end"> {prevButton}</Row>
                </Col>
                <Col sm="5">
                  <Row className="justify-content-start">
                    <div className="ml-1">
                      <button className="no-style-button" type="submit">
                        {nextButton}
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* Only mobile*/}
            <div className="d-sm-none d-block">
              <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
                <div className="d-block ml-5 mr-5">{prevButton}</div>
                <button
                  className="no-style-button d-block ml-5 mr-5 mt-3"
                  type="submit"
                >
                  {nextButton}
                </button>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
}

export default OngoingTreatment;
