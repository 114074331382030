import React from "react";
import { RightArrow } from "./../Default/Icons";

const NextButton = ({ isNextButtonVisible, clickNextButton, pageIndex }) => {
  return (
    <React.Fragment>
      {isNextButtonVisible && (
        <div
          className="app-button text-right"
          onClick={() => clickNextButton(pageIndex)}
        >
          <div className="d-inline">CONTINUER</div>
          <div className="d-inline mr-3 ml-3 mb-5">
            <RightArrow></RightArrow>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NextButton;
