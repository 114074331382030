import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import Home from "./../views/Home";
import FormPV from "./../views/FormPV";
import FormAQ from "./../views/FormAQ";
import FormIM from "./../views/FormIM";
import Integration from "./../views/Integration";

const Routes = (props) => {
  return (
    <Router history={props.history}>
      <Switch>
        <Route exact path={`/`} render={() => <Home></Home>} />
        <Route path="/pv" render={() => <FormPV></FormPV>} />
        <Route exact path={`/aq`} render={() => <FormAQ></FormAQ>} />
        <Route exact path={`/im`} render={() => <FormIM></FormIM>} />
        <Route
          exact
          path={`/inte`}
          render={() => <Integration></Integration>}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
