import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  clickNextButton,
  clickPrevButton,
  setPersonWithSideEffect,
  setPharmacovigilanceSituation,
  setSideEffectDescription,
  setPersonSideEffectEvent,
  setPvProfessionnelDetails,
  setMedicalBackground,
  setOngoingTreatment,
  setPvDrugConcerned,
  setPvEffectFollowingSubstitution,
  setDrugHistory,
  setPvGravity,
  setPvAppIdentity,
  sendFormPV,
} from "./../redux/actions/pvActions";
import history from "./../history";
import PersonWithSideEffect from "../components/Pv/PersonWithSideEffect";
import PharmacovigilanceSituation from "../components/Pv/PharmacovigilanceSituation";
import SideEffectsDescription from "../components/Pv/SideEffectsDescription";
import SideEffectEvents from "../components/Pv/SideEffectsEvents";
import DrugConcerned from "../components/Pv/DrugConcerned";
import ProfessionnelDetails from "../components/Pv/ProfessionnelDetails";
import MedicalBackground from "../components/Pv/MedicalBackground";
import Gravity from "../components/Pv/Gravity";
import OngoingTreatment from "../components/Pv/OngoingTreatment";
import SideEffectFollowingSubstitution from "../components/Pv/SideEffectFollowingSubstitution";
import PrevButton from "./../components/button/PrevButton";
import NextButton from "./../components/button/NextButton";
import DrugHistory from "../components/Pv/DrugHistory";
import ContactBlock from "../components/Aq/ContactBlock";
import ThanksBlock from "../components/Aq/ThanksBlock";
import BackendErrorBlock from "../components/Aq/BackendErrorBlock";
import Stepper from "react-js-stepper";

class PvContainer extends Component {
  componentDidMount() {
    const { home } = this.props;
    if (home.pageIndex === 0 && !home.app_tpa_id && !home.pvi_type) {
      history.push("/");
    }
  }
  render() {
    const {
      pv,
      home,
      clickNextButton,
      clickPrevButton,
      setPersonWithSideEffect,
      setPharmacovigilanceSituation,
      setSideEffectDescription,
      setPersonSideEffectEvent,
      setPvProfessionnelDetails,
      setMedicalBackground,
      setOngoingTreatment,
      setPvDrugConcerned,
      setPvEffectFollowingSubstitution,
      setDrugHistory,
      setPvGravity,
      setPvAppIdentity,
      sendFormPV,
    } = this.props;

    //components in home container page index 0-2
    //components in pv container page index 3-12
    const components = {
      3: (
        <PersonWithSideEffect
          personWithSideEffect={pv.pv_person_with_side_effect}
          setPersonWithSideEffect={setPersonWithSideEffect}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
          appProOrNotpro={home.app_pro_or_notpro}
        ></PersonWithSideEffect>
      ),
      4: (
        <DrugConcerned
          drugConcern={pv.pv_drug_concerned}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
          setPvDrugConcerned={setPvDrugConcerned}
          appProOrNotpro={home.app_pro_or_notpro}
        ></DrugConcerned>
      ),
      5: (
        <SideEffectEvents
          setPersonSideEffectEvent={setPersonSideEffectEvent}
          personSideEffectEvent={pv.pv_side_effect_event}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
        ></SideEffectEvents>
      ),
      6: (
        <SideEffectsDescription
          sideEffectsDescription={pv.pv_side_effect_description}
          setSideEffectDescription={setSideEffectDescription}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
          appProOrNotpro={home.app_pro_or_notpro}
        ></SideEffectsDescription>
      ),
      7: (
        <PharmacovigilanceSituation
          pharmacovigilanceSituation={pv.pv_pharmacovigilance_situation}
          setPharmacovigilanceSituation={setPharmacovigilanceSituation}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
          appProOrNotpro={home.app_pro_or_notpro}
        ></PharmacovigilanceSituation>
      ),
      8: (
        <SideEffectFollowingSubstitution
          sideEffectFollowingSubstitution={
            pv.pv_side_effect_following_substitution
          }
          setPvEffectFollowingSubstitution={setPvEffectFollowingSubstitution}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
          appProOrNotpro={home.app_pro_or_notpro}
        ></SideEffectFollowingSubstitution>
      ),
      9: (
        <Gravity
          setGravity={setPvGravity}
          gravity={pv.pv_gravity}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
        ></Gravity>
      ),
      10: (
        <OngoingTreatment
          ongoingTreatment={pv.pv_ongoing_treatment}
          setOngoingTreatment={setOngoingTreatment}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
          appProOrNotpro={home.app_pro_or_notpro}
        ></OngoingTreatment>
      ),
      11: (
        <MedicalBackground
          medicalBackground={pv.pv_medical_background}
          setMedicalBackground={setMedicalBackground}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
        ></MedicalBackground>
      ),
      12: (
        <DrugHistory
          drugHistory={pv.pv_drug_history}
          setDrugHistory={setDrugHistory}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
        ></DrugHistory>
      ),
      13: (
        <ProfessionnelDetails
          ProDetails={pv.pv_professionnel_details}
          setProDetails={setPvProfessionnelDetails}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
        ></ProfessionnelDetails>
      ),

      14: (
        <ContactBlock
          appTpaId={home.app_tpa_id}
          contact={pv.app_identity}
          parentCallback={setPvAppIdentity}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={pv.pageIndex}
          formData={{ pv, home }}
          sendFormDataButton={sendFormPV}
          pviType={home.pvi_type}
        ></ContactBlock>
      ),
      15: <ThanksBlock></ThanksBlock>,
      16: <BackendErrorBlock formData={pv}></BackendErrorBlock>
    };
    //array for not display btn next or prev
    const pageIndexExculForBtn = [3, 5, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,16];

    // Add specific number of steps according to the user profile
    // pro = 11 steps / patient = 9 steps
    const steps =
      home.app_pro_or_notpro !== "Patient"
        ? [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
        : [{}, {}, {}, {}, {}, {}, {}, {}, {}];

    return (
      <Fragment>
        <Stepper steps={steps} activeStep={pv.step} showNumber={false} />
        {components[pv.pageIndex]}

        {/* Only desktop*/}
        {!pageIndexExculForBtn.includes(pv.pageIndex) && (
          <div className="d-none d-sm-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ">
              <Col sm="4" lg="3" className="mr-4 mt-2">
                <Row className="justify-content-end">
                  <PrevButton
                    isPrevButtonVisible={pv.isPrevButtonVisible}
                    clickPrevButton={clickPrevButton}
                    pageIndex={pv.pageIndex}
                  ></PrevButton>
                </Row>
              </Col>
              <Col sm="4" lg="3" className="ml-4 mt-2">
                <Row className="justify-content-start">
                  <NextButton
                    isNextButtonVisible={pv.isNextButtonVisible}
                    clickNextButton={clickNextButton}
                    pageIndex={pv.pageIndex}
                  ></NextButton>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        {/* Only mobile*/}
        {!pageIndexExculForBtn.includes(pv.pageIndex) && (
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">
                <PrevButton
                  isPrevButtonVisible={pv.isPrevButtonVisible}
                  clickPrevButton={clickPrevButton}
                  pageIndex={pv.pageIndex}
                ></PrevButton>
              </div>

              <NextButton
                isNextButtonVisible={pv.isNextButtonVisible}
                clickNextButton={clickNextButton}
                pageIndex={pv.pageIndex}
              ></NextButton>
            </Row>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ home: state.home, pv: state.pv });

export default connect(mapStateToProps, {
  clickNextButton,
  clickPrevButton,
  setPersonWithSideEffect,
  setPharmacovigilanceSituation,
  setSideEffectDescription,
  setPersonSideEffectEvent,
  setPvProfessionnelDetails,
  setMedicalBackground,
  setOngoingTreatment,
  setPvDrugConcerned,
  setPvEffectFollowingSubstitution,
  setDrugHistory,
  setPvGravity,
  setPvAppIdentity,
  sendFormPV,
})(PvContainer);
