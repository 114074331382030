import React from "react";
import { Col, Row } from "react-bootstrap";
import Tooltips from "./Tooltips";
function FormCheckBox({ items, reference, name }) {
  const checkbox = items.map((item, index) => {
    return (
      <Col md="6" key={index}>
        <div
          className="custom-control custom-checkbox"
          style={{ position: "relative" }}
        >
          <input
            autoComplete="off"
            ref={reference}
            type="checkbox"
            name={name}
            value={item.id}
            id={name + item.id} // Unique ID
            className="custom-control-input"
          />
          <label className="custom-control-label" htmlFor={name + item.id}>
            {item.label}
            {item.tooltip && (
              <Tooltips
                content={item.tooltip}
                direction="right"
                selector="tooltip-checkBox"
              ></Tooltips>
            )}
          </label>
        </div>
      </Col>
    );
  });

  return <Row>{checkbox}</Row>;
}

export default FormCheckBox;
