import React from "react";
import { Cancel } from "../Default/Icons";

const RemoveButton = ({ clickRemoveButton, className }) => {
  return (
    <React.Fragment>
      <div className={className} onClick={clickRemoveButton}>
        <Cancel></Cancel>
      </div>
    </React.Fragment>
  );
};

export default RemoveButton;
