import React from "react";
import {
  Tablet,
  Nutrition,
  FirstAidKit,
  Meds,
  QrCode,
  Dysfunction,
  Integrity,
  Accessories,
  Doctor,
  User,
  Pharmacy,
} from "../Default/Icons";

export const wording = {
  grand_public: {
    drug_or_box_problem: [
      {
        id: "box",
        title: "Boîte du produit",
        icon: <Tablet></Tablet>,
      },
      {
        id: "box-content",
        title: "Flacon, plaquette de produit, tube, sachet…",
        icon: <Nutrition></Nutrition>,
      },
      {
        id: "drug",
        title: "Gélule, comprimé, poudre, sirop/crème...",
        icon: <Meds></Meds>,
      },
      {
        id: "other",
        title: "Autre",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    box_problem: [
      {
        id: "Boîte endommagée, décollée, ouverte",
        title: "Boîte endommagée, décollée, ouverte ...",
        icon: <Tablet></Tablet>,
      },
      {
        id: "Boite vide ou incomplète",
        title: "Boite vide ou incomplète",
        icon: <Nutrition></Nutrition>,
      },
      {
        id: "Autre",
        title: "Autre défaut",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    drug_problem: [
      {
        id: "produit endommagé",
        title: "produit endommagé",
        icon: <Integrity></Integrity>,
      },
      {
        id: "Goût et/ou odeur inhabituel",
        title: "Goût et/ou odeur inhabituel",
        icon: <Dysfunction></Dysfunction>,
      },
      {
        id: "Autre",
        title: "Autre défaut",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    box_content_problem: [
      {
        id: "Contenant endommagé",
        title: "Contenant endommagé",
        icon: <Tablet></Tablet>,
      },
      {
        id: "Contenant vide ou incomplet",
        title: "Contenant vide ou incomplet",
        icon: <Nutrition></Nutrition>,
      },
      {
        id: "Dysfonctionnement à l'utilisation",
        title: "Dysfonctionnement à l'utilisation",
        icon: <Dysfunction></Dysfunction>,
      },
      {
        id:
          "Accessoire cassé ou manquant (cuillère, gobelet-doseur, pipette ...)",
        title:
          "Accessoire cassé ou manquant (cuillère, gobelet-doseur, pipette ...)",
        icon: <Accessories></Accessories>,
      },
      {
        id: "Autre défaut",
        title: "Autre défaut",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    origin: [
      {
        id: "Patient",
        title: "Vous-même",
        icon: <User></User>,
      },
      {
        id: "Pharmacie",
        title: "Votre pharmacien",
        icon: <FirstAidKit></FirstAidKit>,
      },
      {
        id: "Un aidant ou autre professionnel de santé",
        title: "Un aidant ou autre professionnel de santé",
        icon: <Doctor></Doctor>,
      },
      {
        id: "Autre",
        title: "Autre personne",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    quand: [
      {
        id: "Avant administration",
        title: "Avant la prise du produit",
        icon: <Tablet></Tablet>,
      },
      {
        id: "Après administration",
        title: "Après la prise du produit",
        icon: <Nutrition></Nutrition>,
      },
    ],
  },
  pro: {
    drug_or_box_problem: [
      {
        id: "box",
        title: "Etui",
        icon: <Tablet></Tablet>,
      },
      {
        id: "box-content",
        title: " Flacon, blister, tube, sachet, cuillère-mesure ...",
        icon: <Nutrition></Nutrition>,
      },
      {
        id: "drug",
        title: "Gélule, comprimé, poudre, solution, sirop, crème ...",
        icon: <Meds></Meds>,
      },
      {
        id: "other",
        title: "Autre",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    box_problem: [
      {
        id: "Etui endommagé, décollé, ouvert",
        title: "Etui endommagé, décollé, ouvert ...",
        icon: <Tablet></Tablet>,
      },
      {
        id: "Etui vide ou incomplet",
        title: "Etui vide ou incomplet",
        icon: <Nutrition></Nutrition>,
      },
      {
        id: "Datamatrix",
        title: "Data Matrix",
        icon: <QrCode></QrCode>,
      },
      {
        id: "Autre",
        title: "Autre défaut",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    drug_problem: [
      {
        id: "Intégrité du comprimé",
        title: "Intégrité du comprimé",
        icon: <Integrity></Integrity>,
      },
      {
        id: "Goût et/ou odeur inhabituel",
        title: "Goût et/ou odeur inhabituel",
        icon: <Dysfunction></Dysfunction>,
      },
      {
        id: "Autre",
        title: "Autre défaut",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    box_content_problem: [
      {
        id: "Contenant endommagé",
        title: "Contenant endommagé",
        icon: <Tablet></Tablet>,
      },
      {
        id: "Contenant vide ou incomplet",
        title: "Contenant vide ou incomplet",
        icon: <Nutrition></Nutrition>,
      },
      {
        id: "Dysfonctionnement à l'utilisation",
        title: "Dysfonctionnement à l'utilisation",
        icon: <Dysfunction></Dysfunction>,
      },
      {
        id:
          "Accessoire cassé ou manquant (cuillère, gobelet-doseur, pipette ...)",
        title:
          "Accessoire cassé ou manquant (cuillère, gobelet-doseur, pipette ...)",
        icon: <Accessories></Accessories>,
      },
      {
        id: "Autre",
        title: "Autre défaut",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    origin: [
      {
        id: "Pharmacie",
        title: "Vous-même",
        icon: <FirstAidKit></FirstAidKit>,
      },
      {
        id: "Patient",
        title: "Patient",
        icon: <User></User>,
      },
      {
        id: "Un aidant ou autre professionnel de santé",
        title: "Un aidant ou autre professionnel de santé",
        icon: <Doctor></Doctor>,
      },
      {
        id: "Autre",
        title: "Autre personne",
        icon: <FirstAidKit></FirstAidKit>,
      },
    ],
    quand: [
      {
        id: "Avant administration",
        title: "Avant l’administration du produit",
        icon: <Tablet></Tablet>,
      },
      {
        id: "Après administration",
        title: "Après l’administration du produit",
        icon: <Nutrition></Nutrition>,
      },
      {
        id: "Lors de la délivrance",
        title: "Lors de la délivrance en pharmacie au patient",
        icon: <Pharmacy></Pharmacy>,
      },
      {
        id: "A la réception à la pharmacie",
        title: "A la réception en pharmacie",
        icon: <Pharmacy></Pharmacy>,
      },
    ],
  },
};
