import React from "react";
import { Row, Col } from "react-bootstrap";
import { isIE, isEdge } from "react-device-detect";

function RadioGroup({ items, name, reference }) {
  const radiogroup = items.map((item, i) => {
    var className = "radio-inline";
    if (isIE || isEdge) {
      className = "";
    }
    return (
      <Col xs="10" md="12" lg="6" xl="5" key={i}>
        <div className={className} key={i}>
          <input type="radio" name={name} value={item.id} ref={reference} />
          <label className="ml-2">{item.label}</label>
        </div>
      </Col>
    );
  });

  return <Row>{radiogroup}</Row>;
}

export default RadioGroup;
