import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
const Tooltips = ({ direction, content, selector }) => {
  return (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement={direction}
        overlay={
          <Popover id="popover-basic">
            <Popover.Content>{content}</Popover.Content>
          </Popover>
        }
      >
        {/* USE ID TO CHANGE POSITION OF THE TOOLTIP INFO BUTTON  */}
        <div className={`tooltip-info ${selector}` }>
          i
        </div>
      </OverlayTrigger>
    </>
  );
};

export default Tooltips;
