import React, { useState, useEffect } from "react";
import {
  useForm,
  useFieldArray,
  ErrorMessage,
  Controller,
} from "react-hook-form";
import { Row, Col, Image } from "react-bootstrap";
import ImageUpload from "../ImageUploader";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import DrugDescriptionModal from "../Modal/DrugDescriptionModal";
import { getDrugInfoRequest } from "../../utils/service";
import RemoveButton from "../button/RemoveButton";
import _ from "lodash";
import DrugBox from "../../assets/img/drug-box.png";
import CreatableSelect from "react-select/creatable";

function DrugDescriptionBlock({
  parentCallback,
  drugDescription,
  clickPrevButton,
  clickNextButton,
  pageIndex,
  pvi_type,
  appProOrNotpro,
}) {
  // HOOK FORM
  const { register, handleSubmit, errors, watch, control, setValue } = useForm({
    defaultValues: {
      products: drugDescription?.products,
    },
    criteriaMode: "all",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const [showModal, setShowModal] = useState(false);

  const [pictures, setPictures] = useState();
  const [isConserved, setConserved] = useState("");

  const onSubmit = (data) => {
    if (isConserved === "yes") {
      if (_.isEmpty(pictures) && noPics === false) {
        setErrPics(true);
      } else {
        setErrPics(false);
        setNoPics(false);
        let sendData = Object.assign(
          { ...data },
          { pvi_pda_checked: pda },
          { pvi_pda: pda === true ? "PDA" : "" },
          { selectedOption: drugDescription.selectedOption },
          { drugPreviewPictures: drugPreviewPictures },
          { app_files: pictures }
        );
        parentCallback(sendData);
        setShowModal(true);
        clickNextButton(pageIndex);
      }
    } else {
      setErrPics(false);
      setNoPics(false);
      let sendData = Object.assign(
        { ...data },
        { pvi_pda_checked: pda },
        { pvi_pda: pda === true ? "PDA" : "" },
        { selectedOption: drugDescription.selectedOption },
        { drugPreviewPictures: drugPreviewPictures },
        { app_files: pictures }
      );
      parentCallback(sendData);
      setShowModal(true);
      clickNextButton(pageIndex);
    }
  };
  const [drugList, setDrugList] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const [drugSelectOptions, setDrugSelectOptions] = useState([]);
  const [drugPreviewPictures, setDrugPreviewPictures] = useState(
    drugDescription.drugPreviewPictures
  );

  const [errPics, setErrPics] = useState(false);
  const [noPics, setNoPics] = useState(false);
  const [pda, setPda] = useState(false);

  const onPdaChange = () => {
    if (pda === false) {
      setPda(true);
    } else {
      setPda(false);
    }
  };
  const handleNoPics = () => setNoPics(!noPics);
  // watch pvi_dci field's value
  const watchDci = watch("pvi_dci");

  // useEffect if pvi_dci field is not empty and use Biogaran's API with the value of pvi_dci in query
  useEffect(() => {
    if (watchDci !== "") {
      getDrugInfoRequest(watchDci)
        .then((response) => {
          setDrugSelectOptions(getMarque([response.data]));
        })
        .catch(() => {
          console.log("Error get drug informations");
        });
    }
  }, [watchDci]);

  useEffect(() => {
    if (fields.length === 0) {
      append();
    }
  });

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right">
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  function isConservedChanged(e) {
    setConserved(e.target.value);
    e.target.value === "yes" ? setShowModal(true) : setShowModal(false);
  }

  function handleChange(e) {
    if (e?.obj?.dci) {
      onChange(e.obj.dci);
      e.label = e.obj.dci;
      drugDescription.selectedOption = e;
      drugDescription.pvi_dci = e.obj.dci;

      setValue("pvi_dci", e.obj.dci, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setDrugSelectOptions([]);
      drugDescription.pvi_dci = e?.value;
      setValue("pvi_dci", e?.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }

  function getMarque(options) {
    if (options.length > 0) {
      let result = options[0].map((item) => {
        return {
          key: item.cip13,
          marque: item.marque,
          dosage: item.dosage,
          boitage: item.boitage,
          forme: item.forme_pharma,
        };
      });
      return result;
    }
  }

  // Remove doublons of specific field
  function getUnique(arr, comp) {
    // store the comparison  values in array
    return (
      arr
        .map((e) => e[comp])
        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e])
        .map((e) => arr[e])
    );
  }

  // Update autocomplete drug list
  const onChange = (newValue) => {
    if (newValue !== "") {
      getDrugInfoRequest(newValue)
        .then((response) => {
          setDrugList(response.data);
          if (drugList.length > 0) {
            let optionList = drugList.map((drug) => {
              return {
                value: drug.dci,
                label: drug.dci,
                obj: drug,
                name: drug.dci,
              };
            });
            setDrugOptions(optionList);
          }
        })
        .catch((response) => {
          console.log("catch response + " + response.data);
        });
    }
  };
  const formatDate = (e) => {
    if (e.target.value.length > 2 && !e.target.value.includes("/")) {
      let newValue =
        e.target.value.substring(0, 2) +
        "/" +
        e.target.value.substring(2, e.target.value.length);
      e.target.value = newValue;
    }
  };
  const requiredLot =
    isConserved === "yes"
      ? register({
          required: true,
          maxLength: {
            value: 200,
            message: "Ne doit pas exceder 200 caracteres",
          },
        })
      : register({
          required: false,
          maxLength: {
            value: 200,
            message: "Ne doit pas exceder 200 caracteres",
          },
        });
  const requiredPeremption =
    isConserved === "yes"
      ? register({
          required: "Ce champ est obligatoire",
          pattern: {
            value: /^(0[1-9]|1[0-2])\/?(20[0-9]{2})$/,
            message: "Format de la date invalide ex: 01/2020",
          },
          maxLength: {
            value: 7,
            message: "Ne doit pas exceder 255 caracteres",
          },
        })
      : register({
          required: false,
          pattern: {
            value: /^(0[1-9]|1[0-2])\/?(20[0-9]{2})$/,
            message: "Format de la date invalide ex: 01/2020",
          },
          maxLength: {
            value: 255,
            message: "Ne doit pas exceder 255 caracteres",
          },
        });
  return (
    <Row className="row-no-margin">
      {pvi_type !== "IM" ? (
        <Col xs="12" sm="12" md={{ offset: 1, span: 4 }}>
          <ImageUpload
            setPictures={setPictures}
            previewImages={drugPreviewPictures}
            setPreviewImages={setDrugPreviewPictures}
          ></ImageUpload>
          {errPics === true && (
            <div className="mt-2">
              <p className="mandatory-field">
                Le téléchargement d'une photo du défaut est obligatoire
              </p>
            </div>
          )}
          <div className="checkbox_pics">
            <input
              type="checkbox"
              id="checkboxPics"
              onClick={handleNoPics}
              checked={noPics}
            />
            <label htmlFor="checkboxPics">
              <span>Je ne peux pas fournir la photo pour le moment</span>
            </label>
          </div>
        </Col>
      ) : (
        <Col md={{ offset: 1, span: 4 }}>
          <Row className="justify-content-center">
            <Image
              alt="boîte de produit"
              src={DrugBox}
              width="100%"
              height="100%"
            ></Image>
          </Row>
        </Col>
      )}
      <Col xs="12" sm="12" md={{ span: 6 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* register your input into the hook by invoking the "register" function */}
          <label className="contact-form__label">Nom du produit / DCI *</label>

          <CreatableSelect
            name="selectedOption"
            control={control}
            isClearable
            onChange={handleChange}
            onInputChange={onChange}
            options={_.uniqBy(drugOptions, "label")}
            createOptionPosition="first"
            formatCreateLabel={(input) => input}
            placeholder=""
            noOptionsMessage={() =>
              "Aucun produit correspondant à la recherche"
            }
          />
          <span style={{ fontSize: 12 }}>
            Veuillez saisir le produit concerné en le sélectionnant dans le menu
            déroulant ou, si il n’ apparaît pas, veuillez le saisir directement.
          </span>
          <br />
          <input
            style={{
              height: 0,
              width: 0,
              padding: 0,
              margin: 0,
              float: "right",
              border: "none",
            }}
            autoComplete="off"
            name="pvi_dci"
            ref={register({ required: true })}
          ></input>

          {/* errors will return when field validation fails  */}
          {errors.pvi_dci && (
            <span className="mandatory-field mt-2">
              Ce champ est obligatoire *
            </span>
          )}
          {/* react select fields once drug name is selected */}
          <>
            {/* Marque input */}
            <label className="contact-form__label">Marque du produit</label>
            <Controller
              name="marque"
              control={control}
              onChange={(e) =>
                setValue("pvi_marque", e[0]?.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              as={
                <CreatableSelect
                  className="mb-2"
                  isClearable
                  placeholder=""
                  noOptionsMessage={() => "Aucune marque disponible"}
                  options={getUnique(drugSelectOptions, "marque").map((i) => {
                    return { value: i.marque, label: i.marque };
                  })}
                  formatCreateLabel={(input) => input}
                />
              }
            />

            <input
              style={{
                height: 0,
                width: 0,
                padding: 0,
                margin: 0,
                float: "right",
                border: "none",
              }}
              autoComplete="off"
              name="pvi_marque"
              ref={register({ required: false })}
            ></input>

            {/* Dosage input */}
            <label className="contact-form__label">Dosage</label>

            <Controller
              name="dosage"
              control={control}
              onChange={(e) =>
                setValue("pvi_dosage", e[0]?.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              as={
                <CreatableSelect
                  className="mb-2"
                  isClearable
                  placeholder=""
                  noOptionsMessage={() => "Aucun dosage disponible"}
                  options={getUnique(drugSelectOptions, "dosage").map((i) => {
                    return { value: i.dosage, label: i.dosage };
                  })}
                  formatCreateLabel={(input) => input}
                />
              }
            />

            <input
              style={{
                height: 0,
                width: 0,
                padding: 0,
                margin: 0,
                float: "right",
                border: "none",
              }}
              autoComplete="off"
              name="pvi_dosage"
              ref={register({ required: false })}
            ></input>

            {/* Forme input */}
            <label className="contact-form__label">Forme pharmaceutique</label>

            <Controller
              name="forme"
              control={control}
              onChange={(e) =>
                setValue("pvi_forme", e[0]?.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              as={
                <CreatableSelect
                  className="mb-2"
                  isClearable
                  placeholder=""
                  noOptionsMessage={() =>
                    "Aucune forme pharmaceutique disponible"
                  }
                  options={getUnique(drugSelectOptions, "forme").map((i) => {
                    return { value: i.forme, label: i.forme };
                  })}
                  formatCreateLabel={(input) => input}
                />
              }
            />

            <input
              style={{
                height: 0,
                width: 0,
                padding: 0,
                margin: 0,
                float: "right",
                border: "none",
              }}
              autoComplete="off"
              name="pvi_forme"
              ref={register({ required: false })}
            ></input>

            {appProOrNotpro !== "Patient" && (
              <div className="checkbox_pics">
                <input
                  autoComplete="off"
                  type="checkbox"
                  id="checkbox"
                  name="pvi_pda_checked"
                  value="PDA"
                  defaultChecked={drugDescription.pvi_pda_checked}
                  onClick={onPdaChange}
                  ref={register({ required: false })}
                />
                <label htmlFor="checkbox">
                  <span>PDA</span>
                </label>
              </div>
            )}
            {/* BOITAGE input */}
            <label className="contact-form__label">Boîtage</label>

            <Controller
              name="boitage"
              control={control}
              onChange={(e) =>
                setValue("pvi_boitage", e[0]?.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              as={
                <CreatableSelect
                  className="mb-2"
                  isClearable
                  placeholder=""
                  noOptionsMessage={() => "Aucun boîtage disponible"}
                  options={getUnique(drugSelectOptions, "boitage").map((i) => {
                    return { value: i.boitage, label: i.boitage };
                  })}
                  formatCreateLabel={(input) => input}
                />
              }
            />

            <input
              style={{
                height: 0,
                width: 0,
                padding: 0,
                margin: 0,
                float: "right",
                border: "none",
              }}
              autoComplete="off"
              name="pvi_boitage"
              ref={register({ required: false })}
            ></input>
          </>
          {/* Input nombre de boite avec le defaut remonté du formulaire */}
          {pvi_type !== "IM" && (
            <>
              <label className="contact-form__label">
                Nombre de boîtes avec le défaut
              </label>
              <input
                type="number"
                min="0"
                autoComplete="off"
                className="contact-form__field mb-2"
                placeholder=""
                name={`pvi_rqp_nb_boite`}
                defaultValue={drugDescription.pvi_rqp_nb_boite}
                ref={register({ required: false, maxLength: 10 })}
              />
            </>
          )}

          {/* errors will return when field validation fails  */}
          {errors?.pvi_rqp_nb_boite && (
            <span className="mandatory-field">Ce champ est obligatoire</span>
          )}
          {errors?.pvi_rqp_nb_boite?.type === "maxLength" && (
            <span>La taille ne doit pas dépasser 10 caractères</span>
          )}

          {/**************** LOT *********************/}
          {fields.map((field, index) => (
            <div key={field.id} className="mb-2">
              <hr></hr>
              <Row>
                <Col xs={{ span: "10", order: 0 }} md={{ span: 5, order: 1 }}>
                  <p className="form-label">
                    {isConserved === "yes" ? " N° de lot *" : "N° de lot"}
                  </p>

                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    name={`products[${index}].pvi_rqp_lot`}
                    defaultValue={field.pvi_rqp_lot}
                    ref={requiredLot}
                  />
                  {/* errors will return when field validation fails  */}
                  {errors.products?.[index]?.pvi_rqp_lot?.type ===
                    "required" && (
                    <span className="mandatory-field">
                      Ce champ est obligatoire
                    </span>
                  )}
                </Col>
                <Col xs={{ span: 10, order: 2 }} md={{ span: 5, order: 2 }}>
                  <p className="form-label">
                    {isConserved === "yes"
                      ? "Date de péremption *"
                      : "Date de péremption"}
                  </p>
                  <input
                    autoComplete="off"
                    type="text"
                    className="contact-form__field"
                    placeholder="MM/AAAA"
                    name={`products[${index}].pvi_rqp_peremption`}
                    defaultValue={field.pvi_rqp_peremption}
                    ref={requiredPeremption}
                    onChange={(e) => {
                      formatDate(e);
                    }}
                    maxLength={7}
                  />
                  {/* errors will return when field validation fails  */}
                  <span className="mandatory-field">
                    <ErrorMessage
                      errors={errors}
                      name={`products[${index}].pvi_rqp_peremption`}
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type}>{message}</p>
                        ))
                      }
                    ></ErrorMessage>
                  </span>
                </Col>
                {fields.length > 1 && (
                  <Col
                    xs={{ span: 2, order: -1 }}
                    md={{ span: 2, order: 3 }}
                    style={{ marginTop: "-10px", width: "10px" }}
                  >
                    <RemoveButton
                      className="cancel-button text-center"
                      clickRemoveButton={() => remove(index)}
                    ></RemoveButton>
                  </Col>
                )}
              </Row>
            </div>
          ))}
          <Row className="mb-3">
            <Col md="12" className="my-auto">
              <div
                className="app-form-button text-center d-inline"
                onClick={() => append()}
              >
                AJOUTER UN LOT
              </div>
            </Col>
          </Row>
          {pvi_type !== "IM" && (
            <select
              className="contact-form__field"
              name="product_conserved"
              placeholder="Le produit a-t-il été conservé ? *"
              defaultValue={drugDescription.product_conserved}
              onChange={isConservedChanged}
              ref={register({ required: true })}
            >
              <option value="">Le produit a-t-il été conservé ? *</option>
              <option value="yes">Oui</option>
              <option value="no">Non</option>
            </select>
          )}

          {/* errors will return when field validation fails  */}
          {errors.product_conserved && (
            <span className="mandatory-field">Ce champ est obligatoire</span>
          )}

          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5">
              <Col>
                <div className="d-block ml-5 mr-5 pl-4">{prevButton}</div>
                <button
                  className="no-style-button d-block ml-5 mr-5 mt-3 pl-4"
                  type="submit"
                >
                  {nextButton}
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </Col>
      {showModal && (
        <DrugDescriptionModal
          setShowModal={setShowModal}
          clickNextButton={clickNextButton}
          pageIndex={pageIndex}
        ></DrugDescriptionModal>
      )}
    </Row>
  );
}

export default DrugDescriptionBlock;
