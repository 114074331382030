import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import RadioGroup from "../Default/RadioGroup";
import { PVWording } from "../DataForForm/PVWording";
import FormCheckBox from "../../components/Default/FormCheckbox";
import AddButton from "../button/AddButton";
import RemoveButton from "../button/RemoveButton";
import Tooltips from "../Default/Tooltips";

function SideEffectsDescription({
  sideEffectsDescription,
  setSideEffectDescription,
  clickNextButton,
  clickPrevButton,
  pageIndex,
  appProOrNotpro,
}) {
  //HOOK FORM
  const { register, handleSubmit, control, watch, setValue, errors } = useForm({
    defaultValues: {
      pv_side_effects:
        sideEffectsDescription && sideEffectsDescription.pv_side_effects,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "pv_side_effects",
  });

  // Add the first form in the form array
  useEffect(() => {
    if (fields.length === 0) {
      append();
    }
  });
  const treatmentGroup = PVWording.pro.pvi_pv_treatment;
  const afterSideEffectGroup = PVWording.pro.pvi_pv_after_side_effect;
  const causalityGroup = PVWording.pro.pvi_pv_causality;
  const question =
    appProOrNotpro !== "Patient"
      ? PVWording.pro.question.side_effects_description
      : PVWording.grand_public.question.side_effects_description;
  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setSideEffectDescription(sendData);
    clickNextButton(pageIndex);
  };

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Row className="justify-content-start ml-5">
      <Col xs="11" sm="11">
        <p className="question-label row-no-margin">{question}</p>
      </Col>
      <Col xs="10" md="8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            {fields.map((field, index) => (
              <div key={field.id}>
                <Row>
                  <Col>
                    {index !== 0 && (
                      <RemoveButton
                        className="cancel-button "
                        clickRemoveButton={() => remove(index)}
                      ></RemoveButton>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <label className="contact-form__label">
                      Description de l'effet indésirable et/ou de la situation
                      de pharmacovigilance *
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="10" xl="10">
                    <textarea
                      className="contact-form__field"
                      style={{ height: "150px" }}
                      maxLength="2000"
                      ref={register({ required: true, maxLength: 2000 })}
                      name={`pv_side_effects[${index}].pvi_pv_pharmacovigilance_descr`}
                      defaultValue={field.pvi_pv_pharmacovigilance_descr}
                    ></textarea>
                    {/* errors will return when field validation fails  */}
                    {errors.pv_side_effects &&
                      errors.pv_side_effects[index] &&
                      errors.pv_side_effects[index]
                        .pvi_pv_pharmacovigilance_descr && (
                        <span className="mandatory-field">
                          Ce champ est obligatoire
                        </span>
                      )}
                  </Col>
                  <div className="tooltip-pharmaco-descr">
                    <Tooltips
                      direction="left"
                      content="Exemple : nausées, maux de tête, exposition pendant la grossesse"
                    ></Tooltips>
                  </div>
                </Row>

                <Row>
                  <Col xs="11" md="10" xl="10">
                    <label className="contact-form__label">
                      Date de survenue *
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="10" xl="10">
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      type="text"
                      name={`pv_side_effects[${index}].pvi_pv_date`}
                      defaultValue={field.pvi_pv_date}
                      ref={register({ required: true, maxLength: 255 })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors.pv_side_effects &&
                      errors.pv_side_effects[index] &&
                      errors.pv_side_effects[index].pvi_pv_date && (
                        <span className="mandatory-field">
                          Ce champ est obligatoire
                        </span>
                      )}
                  </Col>
                  <div className="tooltip-date">
                    <Tooltips
                      direction="left"
                      content="Si vous ne connaissez pas la date précise de survenue, indiquez une période approximative"
                    ></Tooltips>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <label>
                      Un traitement correcteur a-t-il été pris pour faire cesser
                      les symptômes en rapport avec l’effet indésirable?
                    </label>
                    <RadioGroup
                      items={treatmentGroup}
                      name={`pv_side_effects[${index}].pvi_pv_treatment`}
                      reference={register({ required: false })}
                    ></RadioGroup>
                  </Col>
                </Row>
                {watch(`pv_side_effects[${index}].pvi_pv_treatment`) ===
                  "Oui" && (
                  <Row>
                    <Col md="10">
                      <label className="contact-form__label">Lequel</label>
                      <textarea
                        className="contact-form__field"
                        type="text"
                        style={{ height: "100px" }}
                        name={`pv_side_effects[${index}].pvi_pv_which_treatment`}
                        defaultValue={field.pvi_pv_which_treatment}
                        ref={register({ required: false, maxLength: 255 })}
                      />
                    </Col>
                    {errors.pv_side_effects &&
                      errors.pv_side_effects[index] &&
                      errors.pv_side_effects[index].pvi_pv_which_treatment &&
                      errors.pv_side_effects[index].pvi_pv_which_treatment
                        .type === "maxLength" && (
                        <span className="mandatory-field ml-3">
                          La taille ne doit pas dépasser 255 caractères
                        </span>
                      )}
                  </Row>
                )}
                <Row>
                  <Col>
                    <label className="form-block__label">
                      Comment l'effet indésirable a-t-il évolué ?
                    </label>                    
                    <RadioGroup
                      items={afterSideEffectGroup}
                      reference={register({ required: false })}
                      name={`pv_side_effects[${index}].pvi_pv_after_side_effect`}
                    ></RadioGroup>
                  </Col>
                </Row>
                {watch(`pv_side_effects[${index}].pvi_pv_after_side_effect`) &&
                  !watch(
                    `pv_side_effects[${index}].pvi_pv_after_side_effect`
                  ).includes("Guéri") &&
                  setValue(`pv_side_effects[${index}].pvi_pv_healing_date`, "")}
                {watch(`pv_side_effects[${index}].pvi_pv_after_side_effect`) &&
                  watch(
                    `pv_side_effects[${index}].pvi_pv_after_side_effect`
                  ).includes("Guéri") && (
                    <Row>
                      <Col md="10">
                        <label className="contact-form__label">
                          Guéri à quelle date ?
                        </label>
                        <input
                          autoComplete="off"
                          className="contact-form__field"
                          type="text"
                          name={`pv_side_effects[${index}].pvi_pv_healing_date`}
                          defaultValue={field.pvi_pv_healing_date}
                          ref={register({ required: false })}
                          maxLength={255}
                        />
                      </Col>
                    </Row>
                  )}
                {watch(`pv_side_effects[${index}].pvi_pv_after_side_effect`) &&
                  !watch(
                    `pv_side_effects[${index}].pvi_pv_after_side_effect`
                  ).includes("Décès") &&
                  setValue(`pv_side_effects[${index}].pvi_pv_death_date`, "")}
                {watch(`pv_side_effects[${index}].pvi_pv_after_side_effect`) &&
                  watch(
                    `pv_side_effects[${index}].pvi_pv_after_side_effect`
                  ).includes("Décès") && (
                    <Row>
                      <Col md="10">
                        <label className="contact-form__label">
                          Décès à quelle date ?
                        </label>
                        <input
                          autoComplete="off"
                          className="contact-form__field"
                          type="text"
                          name={`pv_side_effects[${index}].pvi_pv_death_date`}
                          defaultValue={field.pvi_pv_death_date}
                          ref={register({ required: false })}
                          maxLength={255}
                        />
                      </Col>
                    </Row>
                  )}
                {appProOrNotpro !== "Patient" && (
                  <Row>
                    <Col>
                      <label className="form-block__label">
                        Quelle est la causalité selon vous ?
                      </label>
                      <RadioGroup
                        items={causalityGroup}
                        reference={register({ required: false })}
                        name={`pv_side_effects[${index}].pvi_pv_causality`}
                      ></RadioGroup>
                    </Col>
                  </Row>
                )}
                <hr></hr>
              </div>
            ))}
            <AddButton
              clickAddButton={append}
              label="Ajouter un autre effet indésirable ou une situation de pharmacovigilance​"
            ></AddButton>
          </ul>
          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">{prevButton}</div>
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="submit"
              >
                {nextButton}
              </button>
            </Row>
          </div>
        </form>
      </Col>
    </Row>
  );
}

export default SideEffectsDescription;
