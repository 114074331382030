import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import history from "./../history";
import PrevButton from "./../components/button/PrevButton";
import NextButton from "./../components/button/NextButton";
import DrugDescriptionBlock from "../components/Im/DrugDescriptionBlock";
import {
  clickNextButton,
  clickPrevButton,
  setImDrugConcern,
  setImInfoRequest,
  setImAppIdentity,
  sendFormIM,
} from "./../redux/actions/imActions";
import CommentBlock from "../components/Aq/CommentBlock";
import ContactBlock from "../components/Aq/ContactBlock";
import ThanksBlock from "../components/Aq/ThanksBlock";
import BackendErrorBlock from "../components/Aq/BackendErrorBlock";
import Stepper from "react-js-stepper";

const steps = [{}, {}, {}];

class ImContainer extends Component {
  componentDidMount() {
    const { home } = this.props;
    if (home.pageIndex === 0 && !home.app_tpa_id && !home.pvi_type) {
      history.push("/");
    }
  }
  render() {
    const {
      im,
      home,
      clickNextButton,
      clickPrevButton,
      setImDrugConcern,
      setImInfoRequest,
      setImAppIdentity,
      sendFormIM,
    } = this.props;

    //components in im container page index 3
    const components = {
      3: (
        <Fragment>
          <Row className="justify-content-center mt-5 mb-5 row-no-margin">
            <Col sm="12" xs="12">
              <h2 className="app-subtitle">
                Quel(s) est (sont) le(s) produit(s) concerné(s) par votre
                déclaration?
              </h2>
            </Col>
          </Row>
          <Row>
            <DrugDescriptionBlock
              parentCallback={setImDrugConcern}
              drugDescription={im.im_drug_concern}
              clickNextButton={clickNextButton}
              clickPrevButton={clickPrevButton}
              pageIndex={im.pageIndex}
              pvi_type={home.pvi_type}
              appProOrNotpro={home.app_pro_or_notpro}
            ></DrugDescriptionBlock>
          </Row>
        </Fragment>
      ),
      4: (
        <Row className="justify-content-center">
          <Col xs="11" sm="11">
            <p className="question-label row-no-margin">
              Merci de saisir ici votre demande *
            </p>
          </Col>
          <Col sm="12">
            <CommentBlock
              comment={im.im_info_request}
              parentCallback={setImInfoRequest}
              pageIndex={im.pageIndex}
              clickNextButton={clickNextButton}
              clickPrevButton={clickPrevButton}
            ></CommentBlock>
          </Col>
        </Row>
      ),
      5: (
        <ContactBlock
          appTpaId={home.app_tpa_id}
          contact={im.im_app_identity}
          parentCallback={setImAppIdentity}
          clickNextButton={clickNextButton}
          clickPrevButton={clickPrevButton}
          pageIndex={im.pageIndex}
          formData={{ im, home }}
          sendFormDataButton={sendFormIM}
          pviType={home.pvi_type}
        ></ContactBlock>
      ),
      6: <ThanksBlock></ThanksBlock>,
      7: <BackendErrorBlock formData={im}></BackendErrorBlock>
    };

    //array for not display btn next or prev
    const pageIndexExculForBtn = [3, 4, 5, 6, 7];

    // array for not display layout

    return (
      <Container fluid>
        <Stepper
          steps={steps}
          activeStep={im.pageIndex - 2}
          showNumber={false}
        />
        {/* Slider controller by page index number */}
        <div className="justify-content-center">{components[im.pageIndex]}</div>

        {/* Only desktop*/}
        {!pageIndexExculForBtn.includes(im.pageIndex) && (
          <div className="d-none d-sm-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ">
              <Col sm="4" lg="3" className="mr-4 mt-2">
                <Row className="justify-content-end">
                  <PrevButton
                    isPrevButtonVisible={im.isPrevButtonVisible}
                    clickPrevButton={clickPrevButton}
                    pageIndex={im.pageIndex}
                  ></PrevButton>
                </Row>
              </Col>
              <Col sm="4" lg="3" className="ml-4 mt-2">
                <Row className="justify-content-start">
                  <NextButton
                    isNextButtonVisible={im.isNextButtonVisible}
                    clickNextButton={clickNextButton}
                    pageIndex={im.pageIndex}
                  ></NextButton>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        {/* Only mobile*/}
        {!pageIndexExculForBtn.includes(im.pageIndex) && (
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">
                <PrevButton
                  isPrevButtonVisible={im.isPrevButtonVisible}
                  clickPrevButton={clickPrevButton}
                  pageIndex={im.pageIndex}
                ></PrevButton>
              </div>

              <NextButton
                isNextButtonVisible={im.isNextButtonVisible}
                clickNextButton={clickNextButton}
                pageIndex={im.pageIndex}
              ></NextButton>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  home: state.home,
  im: state.im,
});

export default connect(mapStateToProps, {
  clickNextButton,
  clickPrevButton,
  setImDrugConcern,
  setImInfoRequest,
  setImAppIdentity,
  sendFormIM,
})(ImContainer);
