import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { LeftArrow } from "../Default/Icons";
import SendButton from "./../button/SendButton";

function ValidateEmailModal({
  setShowModal,
  email,
  sendFormDataButton,
  pageIndex,
  formData,
  title,
}) {
  function cancelModal() {
    setShowModal(false);
  }

  const prevButton = (
    <React.Fragment>
      <div className="app-button text-left" onClick={() => cancelModal()}>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <SendButton
      isNextButtonVisible={true}
      clickButton={sendFormDataButton}
      pageIndex={pageIndex}
      formData={formData}
    ></SendButton>
  );

  return (
    <Modal className="app-modal" show={true} backdrop="static" keyboard={false}>
      <h1 className="app-title pt-3" style={{ textAlign: "center" }}>
        {title}
      </h1>
      <p
        className="question-label pt-4 pb-3 pl-3 pr-3"
        style={{ textAlign: "center" }}
      >
        Merci de vérifier qu’il n’y a pas d’erreur de saisie sur votre adresse
        pour vous transmettre votre n° de référence à l’adresse email saisie:
        <br />
        <span style={{ textDecoration: "underline" }}> {email}</span>
      </p>
      <div className="pb-3 pt-3">
        {/* Only desktop*/}
        <div className="d-none d-sm-block">
          <Row className="justify-content-start pt-2 mt-2 ">
            <Col sm="6">
              <Row className="justify-content-end"> {prevButton}</Row>
            </Col>
            <Col sm="5">
              <Row className="justify-content-start ml-1">
                <button className="no-style-button" type="submit">
                  {nextButton}
                </button>
              </Row>
            </Col>
          </Row>
        </div>
        {/* Only mobile*/}
        <div className="d-sm-none d-block">
          <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
            <div className="d-block ml-5 mr-5">{prevButton}</div>
            <button className="d-block ml-5 mr-5 mt-3 no-style-button">
              {nextButton}
            </button>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ValidateEmailModal;
