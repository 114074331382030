import React from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import RadioGroup from "../Default/RadioGroup";
import { PVWording } from "../DataForForm/PVWording";

function SideEffectFollowingDescription({
  sideEffectFollowingSubstitution,
  setPvEffectFollowingSubstitution,
  clickNextButton,
  clickPrevButton,
  pageIndex,
}) {
  //HOOK FORM
  const { register, handleSubmit, watch } = useForm({
    defaultValues: sideEffectFollowingSubstitution,
  });

  const watchFollowingSubstitution = watch("pvi_pv_following_substitution");
  const watchReintroduced = watch("pvi_pv_drug_reintroduced");

  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setPvEffectFollowingSubstitution(sendData);
    clickNextButton(pageIndex);
  };

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Row className="justify-content-start ml-5">
      <Col xs="10" md="8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <p className="question-label row-no-margin">
              Le(s) effets indésirables font-ils suite à une substitution ?
            </p>
            <Col md="10">
              <RadioGroup
                name="pvi_pv_following_substitution"
                items={PVWording.pro.pvi_pv_following_substitution}
                reference={register({ required: false })}
              ></RadioGroup>
              {watchFollowingSubstitution === "OUI" && (
                <>
                  <hr></hr>
                  <RadioGroup
                    name="pvi_pv_substitution_type"
                    items={PVWording.pro.pvi_pv_substitution_type}
                    reference={register({ required: false })}
                  ></RadioGroup>
                  <label className="contact-form__label">Lequel ?</label>
                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    type="text"
                    name="pvi_pv_which"
                    defaultValue={sideEffectFollowingSubstitution.pvi_pv_which}
                    ref={register({ required: false })}
                    maxLength={255}
                  />
                  <Row>
                    <Col md="6">
                      <label className="contact-form__label">
                        Date de début de prise
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name="pvi_pv_start_date"
                        defaultValue={
                          sideEffectFollowingSubstitution.pvi_pv_start_date
                        }
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                    <Col md="6">
                      <label className="contact-form__label">
                        Date de fin de prise
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name="pvi_pv_end_date"
                        defaultValue={
                          sideEffectFollowingSubstitution.pvi_pv_end_date
                        }
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <hr></hr>
                </>
              )}
            </Col>
            {watchFollowingSubstitution === "OUI" && (
              <>
                <p className="question-label row-no-margin">
                  Le patient a-t-il présenté ce(s) effet(s) indésirables(s) lors
                  de ce traitement?
                </p>
                <Col md="10">
                  <RadioGroup
                    name="pvi_pv_side_effect_patient"
                    items={PVWording.pro.pvi_pv_side_effect_patient}
                    reference={register({ required: false })}
                  ></RadioGroup>
                  <hr></hr>
                </Col>
                <p className="question-label row-no-margin">
                  Le princeps ou l’autre générique ont-ils été réintroduits?
                </p>
                <Col md="10">
                  <RadioGroup
                    name="pvi_pv_drug_reintroduced"
                    items={PVWording.pro.pvi_pv_side_effect_patient}
                    reference={register({ required: false })}
                  ></RadioGroup>
                  <hr></hr>
                </Col>
                {watchReintroduced === "OUI" && (
                  <>
                    <Col md="10">
                      <label className="contact-form__label">
                        À quelle date
                      </label>
                      <input
                        autoComplete="off"
                        className="contact-form__field"
                        type="text"
                        name="pvi_pv_drug_reintroduced_date"
                        defaultValue={
                          sideEffectFollowingSubstitution.pvi_pv_drug_reintroduced_date
                        }
                        ref={register({ required: false })}
                        maxLength={255}
                      />
                    </Col>

                    <p className="question-label row-no-margin">
                      Y-a-t-il eu récidive du(des) effet(s) indésirable(s) à la
                      réintroduction?
                    </p>
                    <Col md="10">
                      <RadioGroup
                        name="pvi_pv_recidivism"
                        items={PVWording.pro.pvi_pv_side_effect_patient}
                        reference={register({ required: false })}
                      ></RadioGroup>
                      <hr></hr>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">{prevButton}</div>
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="submit"
              >
                {nextButton}
              </button>
            </Row>
          </div>
        </form>
      </Col>
    </Row>
  );
}

export default SideEffectFollowingDescription;
