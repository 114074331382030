import React from "react";
import { User, Doctor } from "../Default/Icons";
export const HealthProfessional = {
  nonProfessional: [
    {
      id: "Patient",
      title: "Je suis un patient",
      icon: <User />,
    },
  ],

  professional: [
    {
      id: "Officine",
      title: "Pharmacien",
      icon: <Doctor />,
    },
    {
      id: "Médecin",
      title: "Médecin",
      icon: <Doctor />,
    },
    {
      id: "Infirmière",
      title: "Infirmier",
      icon: <Doctor />,
    },
    {
      id: "Autre professionnel de santé",
      title: "Autre professionnel de santé",
      icon: <Doctor />,
    },
  ],
};
