import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PaperHeart from "../../assets/img/paper-heart.png";
import ReturnToHome from "./../button/ReturnToHome";

function ThanksBlock() {
  return (
    <>
      <Row className="justify-content-center mt-5">
        <Col sm="11">
          <h1 className="app-title">Merci pour votre déclaration</h1>
          <h2 className="app-subtitle">
            Vous allez recevoir d’ici quelques minutes un email de confirmation
            avec le numéro de référence à conserver.
            <br />
            Notre équipe pourra vous recontacter en cas de besoin.
            <br />
            Tous les renseignements fournis seront traités dans le respect de la
            confidentialité des données à caractère personnel, du secret médical
            et professionnel.
          </h2>
        </Col>
        <Col className="mt-3" sm="2">
          <Image width="100%" src={PaperHeart}></Image>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <ReturnToHome></ReturnToHome>
      </Row>
    </>
  );
}

export default ThanksBlock;
