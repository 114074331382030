import React from "react";
import FormCheckBox from "../Default/FormCheckbox";
import { Col, Row } from "react-bootstrap";
import { PVWording } from "../DataForForm/PVWording";
import { useForm } from "react-hook-form";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";

function SideEffectEvents({
  setPersonSideEffectEvent,
  clickNextButton,
  clickPrevButton,
  pageIndex,
  personSideEffectEvent,
}) {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: personSideEffectEvent,
  });
  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setPersonSideEffectEvent(sendData);
    clickNextButton(pageIndex);
  };
  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <Row className="justify-content-start ml-5">
      <Col xs="11" sm="11">
        <p className="question-label row-no-margin">
          Votre déclaration concerne (plusieurs choix possibles) ?
        </p>
      </Col>
      <Col md="6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormCheckBox
            parentCallBack={setPersonSideEffectEvent}
            items={PVWording.pro.declararation_concerns}
            name="personSideEffectEvent"
            reference={register({ required: true })}
          />
          {/* errors will return when field validation fails  */}
          {errors.personSideEffectEvent && (
            <span className="mandatory-field">
              Au moins une case doit être cochée
            </span>
          )}
          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">{prevButton}</div>
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="submit"
              >
                {nextButton}
              </button>
            </Row>
          </div>
        </form>
      </Col>
    </Row>
  );
}

export default SideEffectEvents;
