import React from "react";
import { Col, Row } from "react-bootstrap";

const AddButton = ({ clickAddButton, label }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="add-button">
            <div className="add-button__round" onClick={() => clickAddButton()}>
              +
            </div>
            <label className="add-button__label"> {label}</label>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddButton;
