import React, { Component, Fragment } from "react";
import { Container, Row } from "react-bootstrap";
import AddButton from "../components/button/AddButton";
import FormCheckBox from "../components/Default/FormCheckbox";
class IntegrationContainer extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <FormCheckBox
              items={[
                {
                  id: "15",
                  label: "Je suis un particulier",
                },
                {
                  id: "17",
                  label: "Je suis un professionnel de santé",
                },
                {
                  id: "18",
                  label: "Je suis un professionnel de santé",
                },
                {
                  id: "20",
                  label: "Je suis un professionnel de santé",
                },
                {
                  id: "20",
                  label: "Je suis un professionnel de santé",
                },
              ]}
            ></FormCheckBox>
          </Row>

          <AddButton clickAddButton={true} label="Ajouter "></AddButton>
        </Container>
      </Fragment>
    );
  }
}

export default IntegrationContainer;
