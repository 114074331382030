import { combineReducers } from "redux";
import { homeReducer } from "./homeReducer";
import { pvReducer } from "./pvReducer";
import { aqReducer } from "./aqReducer";
import { imReducer } from "./imReducer";

export default combineReducers({
  home: homeReducer,
  pv: pvReducer,
  aq: aqReducer,
  im: imReducer,
});
