import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../Default/Icons";
import AddButton from "../button/AddButton";
import RemoveButton from "../button/RemoveButton";

function DrugHistory({
  setDrugHistory,
  drugHistory,
  clickNextButton,
  clickPrevButton,
  pageIndex,
}) {
  //HOOK FORM
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      drugHistory: drugHistory && drugHistory.drugHistory,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "drugHistory",
  });

  // Add the first form in the form array
  useEffect(() => {
    if (fields.length === 0) {
      append();
    }
  });
  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setDrugHistory(sendData);
    clickNextButton(pageIndex);
  };

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Row className="justify-content-start ml-5">
      <Col xs="11" sm="11">
        <p className="question-label row-no-margin">
          Le patient a-t-il présenté des effets indésirables avec d’autres
          produits ?
        </p>
      </Col>
      <Col xs="10" md="8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            {fields.map((field, index) => (
              <div key={field.id}>
                <Row>
                  <Col>
                    {index !== 0 && (
                      <RemoveButton
                        className="cancel-button"
                        clickRemoveButton={() => remove(index)}
                      ></RemoveButton>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <label className="contact-form__label">
                      Nom du produit
                    </label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      type="text"
                      name={`drugHistory[${index}].pvi_pv_drug_name`}
                      defaultValue={field.pvi_pv_drug_name}
                      ref={register({ required: false })}
                      maxLength={255}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <label className="contact-form__label">
                      Date de prise / en cours
                    </label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      type="text"
                      name={`drugHistory[${index}].pvi_pv_date_taken`}
                      defaultValue={field.pvi_pv_date_taken}
                      ref={register({ required: false })}
                      maxLength={255}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <label className="contact-form__label">
                      Effet(s) indésirable(s) présenté(s)
                    </label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      type="text"
                      name={`drugHistory[${index}].pvi_pv_statut`}
                      defaultValue={field.pvi_pv_statut}
                      ref={register({ required: false })}
                      maxLength={255}
                    />
                  </Col>
                </Row>
                <hr></hr>
              </div>
            ))}
          </ul>
          <AddButton
            clickAddButton={append}
            label=" Ajouter un autre antécédent médicamenteux "
          ></AddButton>
          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">{prevButton}</div>
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="submit"
              >
                {nextButton}
              </button>
            </Row>
          </div>
        </form>
      </Col>
    </Row>
  );
}

export default DrugHistory;
