import {
  SET_APP_TPA_ID,
  SET_PVI_TYPE,
  HOME_CLICK_PREV_BTN,
  HOME_CLICK_NEXT_BTN,
  SET_APP_PRO_OR_NOPRO,
} from "./../actions/actionTypes";

import { fromHomeToOtherHandler } from "./../../utils/function";

const initState = {
  pageIndex: 0,
  app_pro_or_notpro: "",
  app_tpa_id: "",
  pvi_type: "",
  isNextButtonVisible: false,
  isPrevButtonVisible: false,
};

export const homeReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_APP_PRO_OR_NOPRO:
      return {
        ...state,
        app_pro_or_notpro: action.payload.value,
        isNextButtonVisible: true,
      };

    case SET_APP_TPA_ID:
      return {
        ...state,
        app_tpa_id: action.payload.value,
        isNextButtonVisible: true,
      };
    case SET_PVI_TYPE:
      return {
        ...state,
        pvi_type: action.payload.value,
        isNextButtonVisible: true,
      };
    case HOME_CLICK_PREV_BTN:
      let showBtnPre = true;
      if (state.app_pro_or_notpro === "Patient" && state.pageIndex === 2) {
        --action.payload.pageIndex;
        showBtnPre = false;
      }
      return {
        ...state,
        pageIndex: --action.payload.pageIndex,
        isNextButtonVisible: true,
        isPrevButtonVisible:
          state.pageIndex > 1 && state.app_tpa_id && showBtnPre ? true : false,
      };
    case HOME_CLICK_NEXT_BTN:
      if (state.app_pro_or_notpro === "Patient" && state.pageIndex === 0) {
        ++action.payload.pageIndex;
        state.app_tpa_id = state.app_pro_or_notpro;
      }
      let result = {
        ...state,
        pageIndex:
          state.pageIndex < 2 ? ++action.payload.pageIndex : state.pageIndex,
        isNextButtonVisible: state.pvi_type ? true : false,
        isPrevButtonVisible: true,
      };

      if (state.pageIndex >= 2)
        fromHomeToOtherHandler(state.pvi_type, result.pageIndex, 2);

      return result;
    default:
      return state;
  }
};
