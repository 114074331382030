import React, { useState } from "react";
import FormBlock from "../Default/FormBlock";
import { HomeWording } from "../DataForForm/HomeWording";
import ProblemBlockModal from "../Modal/ProblemBlockModal";
import { Col, Row } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import _ from "lodash";
function ProblemBlock({
  parentCallback,
  selectedValue,
  appProOrNotpro,
  pageIndex,
  clickNextButton,
  clickPrevButton,
}) {
  const wording =
    appProOrNotpro !== "Patient"
      ? HomeWording.pro.home_problem_block_pro
      : HomeWording.grand_public.home_problem_block;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    clickNextButton(pageIndex);
  };
  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div
        className="app-button text-right"
        onClick={
          selectedValue === "PV"
            ? () => setShow(true)
            : () => clickNextButton(pageIndex)
        }
      >
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <>
      <FormBlock
        parentCallback={parentCallback}
        selectedValue={selectedValue}
        items={wording}
      ></FormBlock>
      <ProblemBlockModal show={show} onHide={handleClose}></ProblemBlockModal>

      <Col sm="12">
        {/* Only desktop*/}
        <div className="d-none d-sm-block">
          <Row className="justify-content-start pt-2 mt-2 ">
            <Col sm="6">
              <Row className="justify-content-end"> {prevButton}</Row>
            </Col>{" "}
            {!_.isEmpty(selectedValue) && (
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="button">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            )}
          </Row>
        </div>
        {/* Only mobile*/}
        <div className="d-sm-none d-block">
          <Row className="justify-content-center pt-5 pb-5 mb-5 ml-5 mr-5">
            <div className="d-block ml-5 mr-5">{prevButton}</div>
            {!_.isEmpty(selectedValue) && (
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="button"
              >
                {nextButton}
              </button>
            )}
          </Row>
        </div>
      </Col>
    </>
  );
}

export default ProblemBlock;
