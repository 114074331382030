import React from "react";
import { useForm } from "react-hook-form";
import RadioGroup from "../Default/RadioGroup";
import { Col, Row } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import { PVWording } from "../DataForForm/PVWording";

function ProfessionnelDetails({
  ProDetails,
  setProDetails,
  clickNextButton,
  clickPrevButton,
  pageIndex,
}) {
  //HOOK FORM
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: ProDetails,
  });

  const watchChoiceMedecin = watch("pvi_pv_accept");

  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setProDetails(sendData);
    clickNextButton(pageIndex);
  };
  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <>
      <Row className="justify-content-center ml-5">
        <Col xs="11" sm="11">
          <p className="question-label row-no-margin">
            Souhaitez-vous communiquer les coordonnées de votre médecin traitant
            ou de celui qui a prescrit le(s) produit(s) suspects?
          </p>
        </Col>
        <Col xs="10" md="8" lg="5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="10">
                <RadioGroup
                  name="pvi_pv_accept"
                  F
                  items={PVWording.pro.pvi_pv_accept}
                  reference={register({ required: false })}
                ></RadioGroup>
              </Col>
              {watchChoiceMedecin === "OUI" && (
                <>
                  <Col md="10">
                    <label className="contact-form__label">Prénom</label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name="pvi_pv_prenom_pro"
                      defaultValue={ProDetails.pv_pvi_prenom_pro}
                      maxLength="30"
                      ref={register({ required: false, maxLength: 30 })}
                    ></input>
                  </Col>
                  <Col md="10">
                    <label className="contact-form__label">Nom</label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name="pvi_pv_nom_pro"
                      defaultValue={ProDetails.pv_pvi_nom_pro}
                      maxLength="30"
                      ref={register({ required: false, maxLength: 30 })}
                    ></input>
                  </Col>
                  <Col md="10">
                    <label className="contact-form__label">Adresse</label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name="pvi_pv_address_pro"
                      defaultValue={ProDetails.pvi_pv_address_pro}
                      maxLength="50"
                      ref={register({ required: false, maxLength: 50 })}
                    ></input>
                  </Col>
                  <Col md="10">
                    <label className="contact-form__label">Code postal</label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name="pvi_pv_cp_pro"
                      type="number"
                      defaultValue={ProDetails.pvi_pv_cp_pro}
                      maxLength="5"
                      ref={register({ required: false, maxLength: 5 })}
                    ></input>
                    {/* errors will return when field validation fails  */}
                    {errors.pvi_pv_cp_pro &&
                      errors.pvi_pv_cp_pro.type === "maxLength" && (
                        <span className="mandatory-field">
                          La taille ne doit pas dépasser 5 caractères
                        </span>
                      )}
                  </Col>
                  <Col md="10">
                    <label className="contact-form__label">Ville</label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name="pvi_pv_city_pro"
                      defaultValue={ProDetails.pvi_pv_city_pro}
                      maxLength="30"
                      ref={register({ required: false, maxLength: 30 })}
                    ></input>
                  </Col>
                  <Col md="10">
                    <label className="contact-form__label">
                      Numéro de téléphone
                    </label>
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name="pvi_pv_phone_pro"
                      defaultValue={ProDetails.pvi_pv_phone_pro}
                      maxLength="10"
                      type="number"
                      ref={register({
                        required: false,
                        maxLength: 10,
                      })}
                    ></input>
                    {errors.pvi_pv_phone_pro &&
                      errors.pvi_pv_phone_pro.type === "maxLength" && (
                        <span className="mandatory-field">
                          La taille ne doit pas dépasser 10 caractères
                        </span>
                      )}
                  </Col>
                  <Col md="10">
                    <label className="contact-form__label">Email</label>
                    <input
                      autoComplete="off"
                      type="email"
                      className="contact-form__field"
                      name="pvi_pv_mail_pro"
                      defaultValue={ProDetails.pvi_pv_mail_pro}
                      maxLength="50"
                      ref={register({ required: false, maxLength: 50 })}
                    ></input>
                  </Col>
                  <Row>
                    <Col xs="11" sm="11">
                      <p>
                        Biogaran pourra être amené a contacter votre médecin
                        afin de disposer de plus d’informations sur l’(les)
                        effet(s) indésirable(s)/ évènement(s) que vous avez
                        présenté(s).
                      </p>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
            {/* Only desktop*/}
            <div className="d-none d-sm-block">
              <Row className="justify-content-start pt-2 mt-2 ">
                <Col sm="6">
                  <Row className="justify-content-end"> {prevButton}</Row>
                </Col>
                <Col sm="5">
                  <Row className="justify-content-start">
                    <div className="ml-1">
                      <button className="no-style-button" type="submit">
                        {nextButton}
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* Only mobile*/}
            <div className="d-sm-none d-block">
              <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
                <div className="d-block ml-5 mr-5">{prevButton}</div>
                <button
                  className="no-style-button d-block ml-5 mr-5 mt-3"
                  type="submit"
                >
                  {nextButton}
                </button>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
}

export default ProfessionnelDetails;
