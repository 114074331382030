import React from "react";
import FormBlock from "../Default/FormBlock";
import {wording} from "../DataForForm/Wording"

function BoxProblemBlock({
  parentCallback,
  selectedValue,
  appProOrNotpro,
}) {
  const grandPublic = wording.grand_public.box_problem;
  const pro = wording.pro.box_problem;
  const items = appProOrNotpro !== "Patient" ? pro : grandPublic;
  return (
    <FormBlock
      parentCallback={parentCallback}
      selectedValue={selectedValue}
      items={items}
    ></FormBlock>
  );
}

export default BoxProblemBlock;
