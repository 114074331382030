import {
  IM_SET_DRUG_CONCERN,
  IM_CLICK_NEXT_BTN,
  IM_CLICK_PREV_BTN,
  IM_SET_INFO_REQUEST,
  IM_SET_APP_IDENTITY,
  SEND_FORM_IM_FAILED,
  SEND_FORM_IM_SUCCESS,
} from "./actionTypes";
import { sendFormImRequest } from "./../../utils/service";

/**
 * set page index value
 * @param {*} pageIndex
 */
export function clickNextButton(pageIndex) {
  return {
    type: IM_CLICK_NEXT_BTN,
    payload: { pageIndex },
  };
}

/**
 * set page index value
 * @param {*} pageIndex
 */
export function clickPrevButton(pageIndex) {
  return {
    type: IM_CLICK_PREV_BTN,
    payload: { pageIndex },
  };
}
/**
 * setImDrugConcern
 * @param {*} value
 */
export function setImDrugConcern(value) {
  return {
    type: IM_SET_DRUG_CONCERN,
    payload: { value },
  };
}
/**
 * setImInfoRequest
 * @param {*} value
 */
export function setImInfoRequest(value) {
  return {
    type: IM_SET_INFO_REQUEST,
    payload: { value },
  };
}
/**
 * setImAppIdentity
 * @param {*} value
 */
export function setImAppIdentity(value) {
  return {
    type: IM_SET_APP_IDENTITY,
    payload: { value },
  };
}
/** sendFormIMAction
 * @param {*} value
 */
export function sendFormIMAction(value) {
  return {
    type: SEND_FORM_IM_SUCCESS,
    payload: { value },
  };
}
/**
 * sendFormIMFailed
 * @param {*} value
 */
export function sendFormIMFailed(value) {
  return {
    type: SEND_FORM_IM_FAILED,
    payload: { value },
  };
}
/**
 * sendFormIM
 * @param {*} formData 
 * @param {*} pageIndex 
 */
export const sendFormIM = (formData, pageIndex) => (dispatch) => {
  sendFormImRequest(formData)
    .then(async (res) => {
      await dispatch(sendFormIMAction(pageIndex));
    })
    .catch((err) => {
      dispatch(sendFormIMFailed(err));
    });
};
