import React from "react";

const SendButton = ({
  isNextButtonVisible,
  clickButton,
  pageIndex,
  formData,
}) => {
  return (
    <React.Fragment>
      {isNextButtonVisible && (
        <div
          className="app-button text-center"
          onClick={() => clickButton(formData, pageIndex)}
        >
          <div className="d-inline">ENVOYER</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SendButton;
