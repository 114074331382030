import React from "react";
import { Doctor, User } from "../Default/Icons";
import FormBlock from "../Default/FormBlock";

function UserBlock({ parentCallback, selectedValue }) {
  return (
    <FormBlock
      parentCallback={parentCallback}
      selectedValue={selectedValue}
      items={[
        {
          id: "Patient",
          title: "Je suis un patient",
          icon: <User></User>,
        },
        {
          id: "Autre professionnel de santé",
          title: "Je suis un professionnel de santé",
          icon: <Doctor></Doctor>,
        },
      ]}
    ></FormBlock>
  );
}

export default UserBlock;
