import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PaperHeart from "../../assets/img/paper-heart.png";
import ReturnToHome from "../button/ReturnToHome";

function BackendErrorBlock(formData) {
  return (
    <>
      <Row className="justify-content-center mt-5">
        <Col sm="11">
          <h1 className="app-title">Impossible de transmettre votre demande</h1>
          <h2 className="app-subtitle">
          Votre demande n'a pas pu être déposée suite à un problème technique. 
          Vous pouvez envoyer ces informations directement par mail à <a href={encodeURI(`mailto:contact@biogaran.fr?Subject=Transmission d'un formulaire&body=${JSON.stringify(formData, null, " ")}`)} >contact@biogaran.fr</a>.
          </h2>
          <div
            className="app-button btn-retourn-to-home"
            onClick={() => {navigator.clipboard.writeText(JSON.stringify(formData, null, " "))}}
          >
            Copier le formulaire
          </div>
        </Col>        
      </Row>
      <Row className="justify-content-center">
        <ReturnToHome></ReturnToHome>
      </Row>
    </>
  );
}

export default BackendErrorBlock;
