import React from "react";

const ReturnToHome = () => {
  return (
    <div
      className="app-button btn-retourn-to-home"
      onClick={() => {
        return window.location.reload();
      }}
    >
      Nouvelle demande
    </div>
  );
};

export default ReturnToHome;
