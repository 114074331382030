import {
  AQ_CLICK_NEXT_BTN,
  AQ_CLICK_PREV_BTN,
  AQ_SET_APP_IDENTITY,
  AQ_SET_PVI_DENOMINATION,
  AQ_SET_PVI_RQP_RECLAMATION,
  AQ_SET_PVI_RQP_RECLAMATION2,
  AQ_SET_PVI_RQP_RECLAMATION5,
  AQ_SET_PVI_RQP_DECOUVERT,
  AQ_SET_PVI_RQP_QUAND,
  SEND_FORM_AQ_SUCCESS,
  SEND_FORM_AQ_FAILED,
} from "./../actions/actionTypes";
import { isEmpty } from "lodash";
import {
  fromOtherToHomeHandler,
  checkStateValuesForNextBtn,
} from "./../../utils/function";

const initState = {
  pageIndex: 3,
  app_user: "",
  app_identity: {},
  pvi_denomination: {},
  pvi_rqp_reclamation: "",
  pvi_rqp_reclamation2: "",
  pvi_rqp_reclamation5: "",
  pvi_rqp_decouvert: "",
  pvi_rqp_quand: "",
  isNextButtonVisible: false,
  isPrevButtonVisible: true,
};

export const aqReducer = (state = initState, action) => {
  switch (action.type) {
    case AQ_CLICK_PREV_BTN:
      //change route
      fromOtherToHomeHandler(action.payload.pageIndex, 3);
      // if pvi_rqp_reclamation is drug and pageIndex is 7 go to DrugOrBoxProblemBlock
      if (state.pvi_rqp_reclamation === "drug" && state.pageIndex === 7) {
        --action.payload.pageIndex;
        --action.payload.pageIndex;
      }
      // if pvi_rqp_reclamation is box and page is CommentBlock go to boxProblemBlock
      if (state.pvi_rqp_reclamation === "box" && state.pageIndex === 8) {
        --action.payload.pageIndex;
        --action.payload.pageIndex;
      }
      // if pvi_rqp_reclamation is box-content and pageIndex is 6 go to DrugOrBoxProblemBlock
      if (
        state.pvi_rqp_reclamation === "box-content" &&
        state.pageIndex === 6
      ) {
        --action.payload.pageIndex;
      }
      // if pvi_rqp_reclamation is box-content and page is CommentBlock go to boxContentProblemBlock
      if (
        state.pvi_rqp_reclamation === "box-content" &&
        state.pageIndex === 8
      ) {
        --action.payload.pageIndex;
      }
      // if pvi_rqp_reclamation is other and pageIndex is 8 go to DrugOrBoxProblemBlock
      if (state.pvi_rqp_reclamation === "other" && state.pageIndex === 8) {
        --action.payload.pageIndex;
        --action.payload.pageIndex;
        --action.payload.pageIndex;
      }

      let prevResult = {
        ...state,
        pageIndex:
          state.pageIndex > 3 ? --action.payload.pageIndex : state.pageIndex,
        isPrevButtonVisible: true,
        isNextButtonVisible: true,
      };
      return prevResult;
    case AQ_CLICK_NEXT_BTN:
      // if pvi_rqp_reclamation2 is not empty for BoxProblemBlock go to CommentBlock
      if (!isEmpty(state.pvi_rqp_reclamation2) && state.pageIndex === 5) {
        ++action.payload.pageIndex;
        ++action.payload.pageIndex;
      }
      // if drug is selected go to DrugProblemBlock
      if (state.pvi_rqp_reclamation === "drug" && state.pageIndex === 4) {
        ++action.payload.pageIndex;
        ++action.payload.pageIndex;
      }
      // if boc-content is selected go to BoxContentProblemBlock
      if (
        state.pvi_rqp_reclamation === "box-content" &&
        state.pageIndex === 4
      ) {
        ++action.payload.pageIndex;
      }
      // if pvi_rqp_reclamation2 is not empty for BoxContentProblemBlock go to CommentBlock
      if (!isEmpty(state.pvi_rqp_reclamation2) && state.pageIndex === 6) {
        ++action.payload.pageIndex;
      }
      // if other is selected go to CommentBlock
      if (state.pvi_rqp_reclamation === "other" && state.pageIndex === 4) {
        ++action.payload.pageIndex;
        ++action.payload.pageIndex;
        ++action.payload.pageIndex;
      }

      let nextResult = {
        ...state,
        pageIndex: ++action.payload.pageIndex,
        // defaut value of check is false
        isNextButtonVisible: checkStateValuesForNextBtn(state),
        isPrevButtonVisible: true,
      };
      return nextResult;
    case AQ_SET_APP_IDENTITY:
      return {
        ...state,
        app_identity: action.payload.value,
        isNextButtonVisible: true,
      };
    case AQ_SET_PVI_DENOMINATION:
      return {
        ...state,
        pvi_denomination: action.payload.value,
        isNextButtonVisible: true,
      };
    case AQ_SET_PVI_RQP_RECLAMATION:
      return {
        ...state,
        pvi_rqp_reclamation: action.payload.value,
        isNextButtonVisible: true,
      };
    case AQ_SET_PVI_RQP_RECLAMATION2:
      return {
        ...state,
        pvi_rqp_reclamation2: action.payload.value,
        isNextButtonVisible: true,
      };
    case AQ_SET_PVI_RQP_RECLAMATION5:
      return {
        ...state,
        pvi_rqp_reclamation5: action.payload.value,
        isNextButtonVisible: true,
      };
    case AQ_SET_PVI_RQP_DECOUVERT:
      return {
        ...state,
        pvi_rqp_decouvert: action.payload.value,
        isNextButtonVisible: true,
      };
    case AQ_SET_PVI_RQP_QUAND:
      return {
        ...state,
        pvi_rqp_quand: action.payload.value,
        isNextButtonVisible: true,
      };
    case SEND_FORM_AQ_SUCCESS:
      return {
        ...state,
        pageIndex: ++action.payload.value,
      };
    case SEND_FORM_AQ_FAILED:
      return {
        ...state,
        pageIndex: 13,
      };
    default:
      return state;
  }
};
