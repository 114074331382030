import React, { Fragment } from "react";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import RadioGroup from "../Default/RadioGroup";
import { PVWording } from "../DataForForm/PVWording";

function PharmacovigilanceSituation({
  clickNextButton,
  clickPrevButton,
  pageIndex,
  pharmacovigilanceSituation,
  setPharmacovigilanceSituation,
  appProOrNotpro,
}) {
  //HOOK FORM
  const { register, handleSubmit, watch } = useForm({
    defaultValues: pharmacovigilanceSituation,
  });
  const watchComplementaryExams = watch("pvi_pv_examen");
  const watchHospitalisation = watch("pvi_pv_hospitalisation");

  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setPharmacovigilanceSituation(sendData);
    clickNextButton(pageIndex);
  };

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right">
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <Fragment>
      <Row className="justify-content-center">
        <Col xs="11" sm="11">
          <p className="question-label row-no-margin">
            Pouvez-vous décrire avec précision la situation de Pharmacovigilance
            et/ou de la survenue des effets indésirable présentés?
          </p>
        </Col>
      </Row>
      <Row className="justify-content-start ml-5">
        <Col xs="11" sm="11" md="10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col lg="8" md="10" sm="12" xs="12">
              <textarea
                className="contact-form__field"
                style={{ height: "150px" }}
                placeholder="Le délai d’apparition de l’effet (immédiatement après la prise, progressivement, 2 mois après la première prise ….)
                La description des symptômes et/ou de la situation rencontrée (intensité, évolution des symptômes, …)"
                maxLength="2000"
                ref={register({ required: false, maxLength: 2000 })}
                name="pvi_pv_desciption_probleme"
                defaultValue={
                  pharmacovigilanceSituation.pvi_pv_desciption_probleme
                }
              ></textarea>
            </Col>
            {appProOrNotpro === "Patient" && (
              <Fragment>
                <Col md="10">
                  <label>
                    Est-ce qu’une hospitalisation a été nécessaire suite à la
                    situation de pharmacovigilance et/ou à l’effet indésirable
                    rapporté?
                  </label>
                </Col>
                <Col md="6">
                  <RadioGroup
                    name="pvi_pv_hospitalisation"
                    items={PVWording.pro.pvi_pv_hospitalisation}
                    reference={register({ required: false })}
                  ></RadioGroup>
                </Col>
                {watchHospitalisation === "OUI" && (
                  <>
                    <Row className="justify-content-start ml-1">
                      <Col md="4" lg="2">
                        Date de début
                        <input
                          autoComplete="off"
                          className="contact-form__field"
                          type="date"
                          name="pvi_pv_date_start"
                          defaultValue={
                            pharmacovigilanceSituation.pvi_pv_date_start
                          }
                          ref={register({ required: false })}
                          placeholder="Le"
                        />
                      </Col>
                      <Col md="4" lg="2">
                        Date de fin
                        <input
                          autoComplete="off"
                          className="contact-form__field"
                          type="date"
                          name="pvi_pv_date_end"
                          defaultValue={
                            pharmacovigilanceSituation.pvi_pv_date_end
                          }
                          ref={register({ required: false })}
                          placeholder="Le"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Fragment>
            )}
            <Col md="10">
              <label>Des examens complémentaires ont-ils été réalisés?</label>
            </Col>
            <Col md="6">
              <RadioGroup
                name="pvi_pv_examen"
                items={PVWording.pro.pvi_pv_examen}
                reference={register({ required: false })}
              ></RadioGroup>
            </Col>
            {watchComplementaryExams === "OUI" && (
              <>
                <Col md="8" lg="5">
                  <label className="contact-form__label">Lesquels ? </label>
                  <textarea
                    name="pvi_pv_exam_response"
                    ref={register({ required: false })}
                    defaultValue={
                      pharmacovigilanceSituation.pvi_pv_exam_response
                    }
                    className="contact-form__field"
                    type="text"
                  ></textarea>
                </Col>
              </>
            )}
            {/* Only desktop*/}
            <div className="d-none d-sm-block">
              <Row className="justify-content-start pt-2 mt-2 ">
                <Col sm="6">
                  <Row className="justify-content-end"> {prevButton}</Row>
                </Col>
                <Col sm="5">
                  <Row className="justify-content-start">
                    <div className="ml-1">
                      <button className="no-style-button" type="submit">
                        {nextButton}
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* Only mobile*/}
            <div className="d-sm-none d-block">
              <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
                <div className="d-block ml-5 mr-5">{prevButton}</div>
                <button
                  className="no-style-button d-block ml-5 mr-5 mt-3"
                  type="submit"
                >
                  {nextButton}
                </button>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    </Fragment>
  );
}

export default PharmacovigilanceSituation;
