import React, { Fragment, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DrugBox from "../../assets/img/drug-box.png";
import { Col, Row, Image } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import RadioGroup from "../Default/RadioGroup";
import { PVWording } from "../DataForForm/PVWording";
import AddButton from "../button/AddButton";
import RemoveButton from "../button/RemoveButton";
import CreatableSelect from "react-select/creatable";
import Tooltip from "../Default/Tooltips";
import NestedField from "./NestedField";
import { getDrugInfoRequest } from "../../utils/service";
import { isArray, isEmpty, uniqBy } from "lodash";

function DrugConcerned({
  clickNextButton,
  clickPrevButton,
  pageIndex,
  setPvDrugConcerned,
  drugConcern,
  appProOrNotpro,
}) {
  const { register, handleSubmit, control, watch, setValue, errors } = useForm({
    defaultValues: {
      drugConcerned: drugConcern && drugConcern.drugConcerned,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "drugConcerned",
  });
  var [drugOptions, setDrugOptions] = useState([]);
  var [dciCreatableSelect, setDciCreatableSelect] = useState([]);
  const [drugSelectOptions, setDrugSelectOptions] = useState([]);
  const [pda, setPda] = useState(false);

  const onPdaChange = () => {
    if (pda === false) {
      setPda(true);
    } else {
      setPda(false);
    }
  };
  // placeholder dynamic for input
  const reasonUsing =
    appProOrNotpro === "Patient" ? "Motif d'utilisation" : "Indication";

  /** set up for first block */
  useEffect(() => {
    if (fields.length === 0) {
      append();
    }
  });

  /**
   * on Submit
   * @param {*} data
   */
  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setPvDrugConcerned(sendData);
    clickNextButton(pageIndex);
  };

  /**
   * on Input Change
   * @param {*} newValue
   * @param {*} index
   */
  const onInputChange = (newValue) => {
    if (newValue !== "") {
      getDrugInfoRequest(newValue)
        .then((response) => {
          if (response.data.length > 0) {
            let optionList = response.data.map((drug) => {
              return {
                label: setOptionsLabel(drug),
                value: drug.dci,
                obj: drug,
              };
            });
            setDrugOptions([drugObjetCreator(newValue), ...optionList]);
          }
        })
        .catch((response) => {
          console.log("catch response + " + response.data);
        });
    }
  };

  function getMarque(options) {
    if (options.length > 0) {
      let result = options[0].map((item) => {
        return {
          key: item.cip13,
          marque: item.marque,
          dosage: item.dosage,
          forme: item.forme_pharma,
        };
      });
      return result;
    }
  }

  /**
   * handleDrugObjByIndex
   * @param {*} e
   */
  async function handleChange(valueSelected, dciCreatableSelect, index) {
    if (!isEmpty(valueSelected)) {
      valueSelected.index = index;
    }
    // Update select options list for marque, forme, dosage fields
    await getDrugInfoRequest(valueSelected?.value)
      .then((response) => {
        let optionsList = drugSelectOptions;
        optionsList[index] = getMarque([response.data]);
        setDrugSelectOptions(optionsList);
      })
      .catch(() => {
        console.log("Error get drug informations");
      });

    // set value for drug
    setValue(`drugConcerned[${index}].pvi_dci_lable`, valueSelected?.label);
    setValue(`drugConcerned[${index}].pvi_dci`, valueSelected?.value);

    if (!isEmpty(dciCreatableSelect)) {
      let dciCreatableSelectArr = dciCreatableSelect.filter(
        (item) => !isEmpty(item) && item.index !== index
      );
      !isEmpty(valueSelected)
        ? setDciCreatableSelect([valueSelected, ...dciCreatableSelectArr])
        : setDciCreatableSelect([...dciCreatableSelectArr]);
    } else {
      !isEmpty(valueSelected)
        ? setDciCreatableSelect([valueSelected, ...dciCreatableSelect])
        : setDciCreatableSelect([]);
    }
  }

  // Get dosage, marque, or forme corresponding to the selected dci
  function getUnique(index, comp) {
    if (drugSelectOptions[index]) {
      let arr = drugSelectOptions[index];

      return (
        arr
          .map((e) => e[comp])
          // store the indexes of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)
          // eliminate the false indexes & return unique objects
          .filter((e) => arr[e])
          .map((e) => arr[e])
      );
    } else {
      return [];
    }
  }

  /**
   * handleDrugObjByIndex
   * @param {*} dciCreatableSelectObj
   * @param {*} index
   */
  function handleDrugObjByIndex(dciCreatableSelectObj, index) {
    let drug = {};
    if (!isEmpty(dciCreatableSelectObj) && isArray(dciCreatableSelectObj)) {
      drug = dciCreatableSelectObj.find(
        (element) => !isEmpty(element) && element.index === index
      );
    }
    return drug;
  }
  /**
   * setOptionsLabel
   * @param {*} drug
   */
  function setOptionsLabel(drug) {
    return drug.dci;
  }

  /**
   * drug Objet Creator
   * @param {*} inputValue
   */
  function drugObjetCreator(inputValue) {
    return {
      label: inputValue,
      value: inputValue,
      obj: {},
    };
  }

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right">
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <>
      <Row>
        <Col>
          <p className="question-label row-no-margin">
            Quel(s) est (sont) le(s) produit(s) concerné(s) par votre
            déclaration?
          </p>
        </Col>
      </Row>
      <Row className="row-no-margin">
        <Col md={{ offset: 1, span: 4 }} className="justify-content-center ">
          <Image alt="boîte de produit" src={DrugBox} width="100%"></Image>
        </Col>
        <Col md="6">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="justify-content-center mt-3 mb-3"
          >
            {fields.map((field, index) => (
              <div key={field.id}>
                {index !== 0 && (
                  <Row
                    className="mb-3 mt-3 justify-content-end"
                    style={{ marginLeft: 0, marginRight: 0 }}
                  >
                    <RemoveButton
                      className="cancel-button  "
                      clickRemoveButton={() => remove(index)}
                    ></RemoveButton>
                  </Row>
                )}
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">
                      Nom du produit / DCI *
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <CreatableSelect
                      isClearable
                      onChange={(e) =>
                        handleChange(e, dciCreatableSelect, index)
                      }
                      name={`drugConcerned[${index}].pvi_dci_obj`}
                      onInputChange={(e) => onInputChange(e)}
                      options={uniqBy(drugOptions, "label")}
                      defaultValue={
                        !isEmpty(
                          handleDrugObjByIndex(dciCreatableSelect, index)
                        )
                          ? handleDrugObjByIndex(dciCreatableSelect, index)
                          : !isEmpty(field.pvi_dci_lable) &&
                            !isEmpty(field.pvi_dci)
                          ? { label: field.pvi_dci_lable, value: field.pvi_dci }
                          : ""
                      }
                      createOptionPosition="first"
                      formatCreateLabel={(input) => input}
                      placeholder=""
                      noOptionsMessage={() =>
                        "Aucun produit correspondant à la recherche"
                      }
                    />
                    <span style={{ fontSize: 12 }}>
                      Veuillez saisir le produit concerné en le sélectionnant
                      dans le menu déroulant ou, si il n’ apparaît pas, veuillez
                      le saisir directement.
                    </span>
                    <input
                      autoComplete="off"
                      hidden={true}
                      className="contact-form__field"
                      name={`drugConcerned[${index}].pvi_dci_lable`}
                      defaultValue={field.pvi_dci_lable}
                      placeholder="LABEL DU MEDICAMENT / DCI"
                      ref={register({ required: false })}
                    ></input>
                    <input
                      style={{
                        height: 0,
                        width: 0,
                        padding: 0,
                        margin: 0,
                        float: "right",
                        border: "none",
                      }}
                      autoComplete="off"
                      name={`drugConcerned[${index}].pvi_dci`}
                      defaultValue={field.pvi_dci}
                      placeholder="NOM DU MEDICAMENT / DCI *"
                      ref={register({ required: true })}
                    ></input>
                    {/* errors will return when field validation fails  */}
                    {errors?.drugConcerned?.[index]?.pvi_dci && (
                      <span
                        className="mandatory-field"
                        style={{ marginTop: 5, marginBottom: 0 }}
                      >
                        Ce champ est obligatoire *
                      </span>
                    )}
                  </Col>
                </Row>

                {/* Marque input */}
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">
                      Marque du produit
                    </label>
                    <Controller
                      name="marque"
                      control={control}
                      onChange={(e) =>
                        setValue(
                          `drugConcerned[${index}].pvi_marque`,
                          e[0]?.value
                        )
                      }
                      as={
                        <CreatableSelect
                          className="mb-2"
                          isClearable
                          placeholder=""
                          noOptionsMessage={() => "Aucune marque disponible"}
                          options={getUnique(index, "marque").map((i) => {
                            return { value: i.marque, label: i.marque };
                          })}
                          formatCreateLabel={(input) => input}
                        />
                      }
                    />
                  </Col>
                </Row>

                <input
                  style={{
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                    float: "right",
                    border: "none",
                  }}
                  autoComplete="off"
                  name={`drugConcerned[${index}].pvi_marque`}
                  ref={register({ required: false })}
                ></input>

                {/* Dosage input */}
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">Dosage</label>
                    <Controller
                      name="dosage"
                      control={control}
                      onChange={(e) =>
                        setValue(
                          `drugConcerned[${index}].pvi_dosage`,
                          e[0]?.value
                        )
                      }
                      as={
                        <CreatableSelect
                          className="mb-2"
                          isClearable
                          placeholder=""
                          noOptionsMessage={() => "Aucun dosage disponible"}
                          options={getUnique(index, "dosage").map((i) => {
                            return { value: i.dosage, label: i.dosage };
                          })}
                          formatCreateLabel={(input) => input}
                        />
                      }
                    />
                  </Col>
                </Row>

                <input
                  style={{
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                    float: "right",
                    border: "none",
                  }}
                  autoComplete="off"
                  name={`drugConcerned[${index}].pvi_dosage`}
                  ref={register({ required: false })}
                ></input>

                {/* Forme input */}
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">
                      Forme pharmaceutique
                    </label>
                    <Controller
                      name="forme"
                      control={control}
                      onChange={(e) =>
                        setValue(
                          `drugConcerned[${index}].pvi_forme`,
                          e[0]?.value,
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                          }
                        )
                      }
                      as={
                        <CreatableSelect
                          className="mb-2"
                          isClearable
                          placeholder=""
                          noOptionsMessage={() =>
                            "Aucune forme pharmaceutique disponible"
                          }
                          options={getUnique(index, "forme").map((i) => {
                            return { value: i.forme, label: i.forme };
                          })}
                          formatCreateLabel={(input) => input}
                        />
                      }
                    />
                  </Col>
                </Row>

                <input
                  style={{
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                    float: "right",
                    border: "none",
                  }}
                  autoComplete="off"
                  name={`drugConcerned[${index}].pvi_forme`}
                  ref={register({ required: false })}
                ></input>

                {appProOrNotpro !== "Patient" && (
                  <Row>
                    <Col xs="11" md="11" xl="11">
                      <div className="checkbox_pics">
                        <input
                          type="checkbox"
                          id={`checkbox${index}`}
                          name={`drugConcerned[${index}].pvi_pda`}
                          value="PDA"
                          defaultChecked={field.pvi_pda}
                          onClick={onPdaChange}
                          ref={register({ required: false })}
                        />
                        <label htmlFor={`checkbox${index}`}>
                          <span>PDA</span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                )}
                {appProOrNotpro === "Patient" && (
                  <>
                    <input
                      style={{
                        display: "none",
                      }}
                      autoComplete="off"
                      value=""
                      name={`drugConcerned[${index}].pvi_pda`}
                      ref={register({ required: false })}
                    ></input>
                  </>
                )}

                <Row>
                  <Col xs="11" md="11" xl="11">
                    <NestedField
                      nestIndex={index}
                      {...{ control, register, console, errors }}
                    ></NestedField>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">
                      Voie d'administration
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <select
                      className="contact-form__field"
                      name={`drugConcerned[${index}].pvi_voie_administration`}
                      defaultValue={field.pvi_voie_administration}
                      ref={register({ required: false })}
                    >
                      <option value=""></option>
                      {PVWording.pro.pvi_voie_administration.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">{reasonUsing}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name={`drugConcerned[${index}].pvi_reason_using`}
                      defaultValue={field.pvi_reason_using}
                      ref={register({ required: false, maxLength: 255 })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.drugConcerned?.[index]?.pvi_reason_using?.type ===
                      "maxLength" && (
                      <span>La taille ne doit pas dépasser 255 caractères</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">Posologie</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name={`drugConcerned[${index}].pvi_posologie`}
                      defaultValue={field.pvi_posologie}
                      ref={register({ required: false, maxLength: 255 })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.drugConcerned?.[index]?.pvi_posologie?.type ===
                      "maxLength" && (
                      <span>La taille ne doit pas dépasser 255 caractères</span>
                    )}
                  </Col>
                  <Col xs="1" md="1" xl="1">
                    <Tooltip
                      selector="drug_tooltip"
                      direction="left"
                      content="Exemple : 3 comprimés par jour, 1 cuillère mesure matin et soir …​"
                    ></Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">
                      Date de début de la prise
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name={`drugConcerned[${index}].pvi_start_date`}
                      defaultValue={field.pvi_start_date}
                      ref={register({ required: false, maxLength: 255 })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.drugConcerned?.[index]?.pvi_start_date?.type ===
                      "maxLength" && (
                      <span>La taille ne doit pas dépasser 255 caractères</span>
                    )}
                  </Col>
                  <Col xs="1" md="1" xl="1">
                    <Tooltip
                      selector="drug_tooltip"
                      direction="left"
                      content="Exemple : le 01/07/2020, depuis 3 mois, en janvier 2019"
                    ></Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label className="contact-form__label">
                      Durée de la prise du traitement
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <input
                      autoComplete="off"
                      className="contact-form__field"
                      name={`drugConcerned[${index}].pvi_duration`}
                      defaultValue={field.pvi_duration}
                      ref={register({ required: false, maxLength: 255 })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.drugConcerned?.[index]?.pvi_duration?.type ===
                      "maxLength" && (
                      <span>La taille ne doit pas dépasser 255 caractères</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="11" md="11" xl="11">
                    <label>Le produit suspect a-t-il été arrêté ?</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <RadioGroup
                      reference={register({ required: false })}
                      name={`drugConcerned[${index}].pvi_drug_stopped`}
                      items={PVWording.pro.pvi_drug_stopped}
                    ></RadioGroup>
                  </Col>
                </Row>

                {/* Si le produit n'a pas été stopppé mettre toutes les values des inputs suivant a vide */}
                {watch(`drugConcerned[${index}].pvi_drug_stopped`) !== "OUI" &&
                  watch(`drugConcerned[${index}].pvi_recurence_side_effect`) &&
                  setValue(
                    `drugConcerned[${index}].pvi_recurence_side_effect`,
                    ""
                  )}
                {/* Si le produit n'a pas été stopppé mettre toutes les values des inputs suivant a vide */}
                {watch(`drugConcerned[${index}].pvi_drug_stopped`) !== "OUI" &&
                  watch(`drugConcerned[${index}].pvi_drug_reintroduced_date`) &&
                  setValue(
                    `drugConcerned[${index}].pvi_drug_reintroduced_date`,
                    ""
                  )}
                {/* SI le medicament n'a pas  été stoppé et que la data de reintroduction existe mettre la date de reintrocution a vide */}
                {watch(`drugConcerned[${index}].pvi_drug_stopped`) !== "OUI" &&
                  watch(`drugConcerned[${index}].pvi_drug_reintroduced`) &&
                  setValue(`drugConcerned[${index}].pvi_drug_reintroduced`, "")}
                {/* SI le medicament n'a pas  été stoppé et que la data de reintroduction existe mettre la date de fin a vide */}
                {watch(`drugConcerned[${index}].pvi_drug_stopped`) !== "OUI" &&
                  setValue(`drugConcerned[${index}].pvi_end_date`, "")}
                {/* SI le medicament a été stoppé on affiche la date de fin  */}
                {watch(`drugConcerned[${index}].pvi_drug_stopped`) ===
                  "OUI" && (
                  <Fragment>
                    <Row>
                      <Col>
                        <label className="contact-form__label">Date</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          autoComplete="off"
                          className="contact-form__field"
                          name={`drugConcerned[${index}].pvi_end_date`}
                          defaultValue={drugConcern.pvi_end_date}
                          ref={register({ required: false, maxLength: 255 })}
                        />
                        {/* errors will return when field validation fails  */}
                        {errors?.drugConcerned?.[index]?.pvi_end_date?.type ===
                          "maxLength" && (
                          <span>
                            La taille ne doit pas dépasser 255 caractères
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>
                          Le produit suspect a-t-il été réintroduit?
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <RadioGroup
                          name={`drugConcerned[${index}].pvi_drug_reintroduced`}
                          items={PVWording.pro.pvi_drug_reintroduced}
                          reference={register({ required: false })}
                        ></RadioGroup>
                      </Col>
                    </Row>

                    {watch(`drugConcerned[${index}].pvi_drug_reintroduced`) !==
                      "OUI" &&
                      watch(
                        `drugConcerned[${index}].pvi_drug_reintroduced_date`
                      ) &&
                      setValue(
                        `drugConcerned[${index}].pvi_drug_reintroduced_date`,
                        ""
                      )}
                    {/* SI le produit n'a pas été reintroduit mettre la recurence des effetes indésirable a vide */}
                    {watch(`drugConcerned[${index}].pvi_drug_reintroduced`) !==
                      "OUI" &&
                      watch(
                        `drugConcerned[${index}].pvi_recurence_side_effect`
                      ) &&
                      setValue(
                        `drugConcerned[${index}].pvi_recurence_side_effect`,
                        ""
                      )}
                    {/* SI le produit a été reintroduit on affiche la date de reintroduction */}
                    {watch(`drugConcerned[${index}].pvi_drug_stopped`) ===
                      "OUI" &&
                      watch(`drugConcerned[${index}].pvi_drug_reintroduced`) ===
                        "OUI" && (
                        <Fragment>
                          <Row>
                            <Col>
                              <label className="contact-form__label">
                                Date
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <input
                                autoComplete="off"
                                className="contact-form__field"
                                name={`drugConcerned[${index}].pvi_drug_reintroduced_date`}
                                defaultValue={field.pvi_drug_reintroduced_date}
                                ref={register({ required: false })}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label>
                                Récidive du (des) effet(s) indésirable(s)?
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <RadioGroup
                                name={`drugConcerned[${index}].pvi_recurence_side_effect`}
                                items={PVWording.pro.pvi_recurence_side_effect}
                                reference={register({ required: false })}
                              ></RadioGroup>
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                  </Fragment>
                )}
              </div>
            ))}
            <hr></hr>
            <div className="mb-3">
              <AddButton
                clickAddButton={append}
                label="Ajouter un produit"
              ></AddButton>
            </div>
            {/* Only desktop*/}
            <div className="d-none d-sm-block">
              <Row className="justify-content-start pt-2 mt-2 ">
                <Col sm="6">
                  <Row className="justify-content-end"> {prevButton}</Row>
                </Col>
                <Col sm="5">
                  <Row className="justify-content-start">
                    <div className="ml-1">
                      <button className="no-style-button" type="submit">
                        {nextButton}
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* Only mobile*/}
            <div className="d-sm-none d-block">
              <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
                <div className="d-block ml-5 mr-5">{prevButton}</div>
                <button
                  className="no-style-button d-block ml-5 mr-5 mt-3"
                  type="submit"
                >
                  {nextButton}
                </button>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
}

export default DrugConcerned;
