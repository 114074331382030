import React from "react";
import { LeftArrow } from "./../Default/Icons";

const PrevButton = ({ isPrevButtonVisible, clickPrevButton, pageIndex }) => {
  return (
    <React.Fragment>
      {isPrevButtonVisible && (
        <div
          className="app-button text-left"
          onClick={() => clickPrevButton(pageIndex)}
        >
          <div className="d-inline mr-3 ml-3 mb-5">
            <LeftArrow></LeftArrow>
          </div>
          <div className="d-inline">RETOUR</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PrevButton;
