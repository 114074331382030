import React from "react";
import DrugBox from "../assets/img/drug-box.png";
import { Row, Image } from "react-bootstrap";
import ImageUploader from "react-images-upload";

function ImageUpload({ previewImages, setPreviewImages, setPictures }) {
  function onDrop(files, pictures) {
    setPreviewImages(pictures);
    setPictures(files);
  }

  return (
    <Row className="justify-content-center">
      <Image
        alt="boîte du produit"
        src={DrugBox}
        width="100%"
        height="100%"
      ></Image>
      <ImageUploader
        defaultImages={previewImages}
        buttonClassName="button-upload"
        withIcon={false}
        buttonText="Joindre une photo du défaut"
        label="Taille maximale du fichier: 5 Mo, acceptée: jpg, png, jpeg"
        onChange={(files, pictures) => onDrop(files, pictures)}
        imgExtension={[".jpg", ".png",".jpeg"]}
        maxFileSize={5242880}
        withPreview={true}
        fileTypeError="Format d'image non supporté (formats acceptés: .jpg, .png, .jpeg)"
        fileSizeError="Image trop lourde"
      />
    </Row>
  );
}

export default ImageUpload;
