import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { PVWording } from "../DataForForm/PVWording";
import { useForm } from "react-hook-form";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import RadioGroup from "../Default/RadioGroup";
import FormCheckBox from "../Default/FormCheckbox";

function Gravity({
  setGravity,
  clickNextButton,
  clickPrevButton,
  pageIndex,
  gravity,
}) {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: gravity,
  });
  const watchGravity = watch("gravity");
  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setGravity(sendData);
    clickNextButton(pageIndex);
  };
  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <Row className="justify-content-center">
      <Col xs="11" sm="11">
        <p className="question-label row-no-margin">
          Quelle est la gravité du cas selon vous?
        </p>
      </Col>
      <Col xs="12" md="8" xl="6" className="ml-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <RadioGroup
            items={PVWording.pro.pvi_pv_gravity}
            name="gravity"
            reference={register({ required: true })}
          />
          {/* errors will return when field validation fails  */}
          {errors.gravity && (
            <span className="mandatory-field mt-2">
              Ce champ est obligatoire
            </span>
          )}
          {/* Display only if "Grave" */}
          {watchGravity === "Grave" && (
            <Fragment>
              <Row>
                <Col md="6" lg="6" xs="11" sm="11">
                  <hr></hr>
                </Col>
              </Row>

              <FormCheckBox
                items={PVWording.pro.pvi_pv_result}
                name="pvi_pv_result"
                reference={register({ required: true })}
              ></FormCheckBox>
              {errors.pvi_pv_result && (
                <span className="mandatory-field mb-2">
                  Vous devez saisir au moins une valeur
                </span>
              )}
              {watch("pvi_pv_result") && (
                <>
                  {watch("pvi_pv_result").includes("Décès") && (
                    <>
                      <Row>
                        <Col md="6" lg="6" xs="11" sm="11">
                          Date du décès
                          <input
                            autoComplete="off"
                            type="text"
                            className="contact-form__field"
                            name="pvi_pv_death_date"
                            placeholder="Le"
                            defaultValue={gravity.pvi_pv_death_date}
                            ref={register({ required: false })}
                          />
                          {errors.pvi_pv_death_date && (
                            <span className="mandatory-field mb-2">
                              Ce champ est obligatoire
                            </span>
                          )}
                        </Col>
                      </Row>
                      <Col md="6" lg="6" xs="11" sm="11">
                        <label>
                          Est-il selon vous, rapporté à (aux) effet(s)
                          indésirable(s) :
                        </label>
                      </Col>
                      <RadioGroup
                        items={PVWording.pro.pvi_pv_cause_death}
                        name="pvi_pv_cause_death"
                        reference={register({ required: true })}
                      ></RadioGroup>
                      {errors.pvi_pv_cause_death && (
                        <span className="mandatory-field mt-2 mb-2">
                          Ce champ est obligatoire
                        </span>
                      )}
                    </>
                  )}
                  {watch("pvi_pv_result").includes("Hospitalisation") && (
                    <Row>
                      <Col md="6" lg="6" xs="11" sm="11">
                        Début de l'hospitalisation
                        <input
                          autoComplete="off"
                          type="text"
                          className="contact-form__field"
                          name="pvi_pv_hospitalization_start_date"
                          placeholder="Du"
                          defaultValue={
                            gravity.pvi_pv_hospitalization_start_date
                          }
                          ref={register({ required: false })}
                        />
                        {errors.pvi_pv_hospitalization_start_date && (
                          <span className="mandatory-field mb-2">
                            Ce champ est obligatoire
                          </span>
                        )}
                      </Col>
                      <Col md="6" lg="6" xs="11" sm="11">
                        Fin de l'hospitalisation
                        <input
                          autoComplete="off"
                          type="text"
                          className="contact-form__field"
                          name="pvi_pv_hospitalization_end_date"
                          placeholder="Au"
                          defaultValue={gravity.pvi_pv_hospitalization_end_date}
                          ref={register({ required: false })}
                        />
                        {errors.pvi_pv_hospitalization_end_date && (
                          <span className="mandatory-field mb-2">
                            Ce champ est obligatoire
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Fragment>
          )}

          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">{prevButton}</div>
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="submit"
              >
                {nextButton}
              </button>
            </Row>
          </div>
        </form>
      </Col>
    </Row>
  );
}

export default Gravity;
