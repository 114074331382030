import React from "react";
import { Row, Col } from "react-bootstrap";
import Tooltips from "./Tooltips";

function FormCard(props) {
  // Declare a new state variable, which we'll call "count"
  return (
    <React.Fragment>
      <Row
        className={`${
          props.state === props.id
            ? "form-card form-card--selected"
            : "form-card"
        }`}
      >
        {props.tooltip && (
          <Tooltips
            content={props.tooltip}
            direction="right"
            selector="tooltip-card"
          ></Tooltips>
        )}
        <Col md="12" className="text-center form-card__icon">
          {props.icon}
        </Col>

        <Col md="12" className="form-card__text">
          {props.title}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default FormCard;
