import React, { useState } from "react";
import { Row } from "react-bootstrap";
import FormCard from "./FormCard";

function FormBlock({ parentCallback, items, selectedValue }) {
  const [selected, setSelected] = useState(selectedValue);

  const cards = items.map((item, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          parentCallback(item.id);
          setSelected(item.id);
        }}
        className="form-block__flex-item"
      >
        <FormCard
          tooltip={item.tooltip}
          title={item.title}
          icon={item.icon}
          id={item.id}
          state={selected}
        ></FormCard>
      </div>
    );
  });

  return <Row className="justify-content-center">{cards}</Row>;
}

export default FormBlock;
