import React from "react";
import FormBlock from "../Default/FormBlock";
import {wording} from '../DataForForm/Wording'
// TODO set good id
function BoxContentProblemBlock({
  parentCallback,
  appProOrNotpro,
  selectedValue,
}) {
  const grandPublic = wording.grand_public.box_content_problem;
  const pro = wording.pro.box_content_problem;
  const items = appProOrNotpro !== "Patient" ? pro : grandPublic;

  return (
    <FormBlock
      parentCallback={parentCallback}
      selectedValue={selectedValue}
      items={items}
    ></FormBlock>
  );
}

export default BoxContentProblemBlock;