import React from "react";
import FormBlock from "../Default/FormBlock";
import { HealthProfessional } from "../DataForForm/HealthProfessional";

function HealthProfessionalChoice({
  parentCallback,
  selectedValue,
  appProOrNotpro,
}) {
  const userChoice =
    appProOrNotpro === "Patient"
      ? HealthProfessional.nonProfessional
      : HealthProfessional.professional;

  return (
    <FormBlock
      parentCallback={parentCallback}
      selectedValue={selectedValue}
      items={userChoice}
    ></FormBlock>
  );
}

export default HealthProfessionalChoice;
