import {
  IM_CLICK_PREV_BTN,
  IM_CLICK_NEXT_BTN,
  IM_SET_DRUG_CONCERN,
  IM_SET_INFO_REQUEST,
  IM_SET_APP_IDENTITY,
  SEND_FORM_IM_SUCCESS,
  SEND_FORM_IM_FAILED,
} from "./../actions/actionTypes";

import { fromOtherToHomeHandler } from "./../../utils/function";

const initState = {
  pageIndex: 3,
  im_drug_concern: {},
  isNextButtonVisible: false,
  isPrevButtonVisible: true,
  im_info_request: "",
  im_app_identity: {},
};

export const imReducer = (state = initState, action) => {
  switch (action.type) {
    case IM_CLICK_PREV_BTN:
      //change route
      fromOtherToHomeHandler(action.payload.pageIndex, 3);

      let prevResult = {
        ...state,
        pageIndex:
          state.pageIndex > 3 ? --action.payload.pageIndex : state.pageIndex,
        isPrevButtonVisible: true,
        isNextButtonVisible: true,
      };
      return prevResult;
    case IM_CLICK_NEXT_BTN:
      let nextResult = {
        ...state,
        pageIndex: ++action.payload.pageIndex,
        isNextButtonVisible: false,
        isPrevButtonVisible: true,
      };
      return nextResult;
    case IM_SET_DRUG_CONCERN:
      return {
        ...state,
        im_drug_concern: action.payload.value,
        isNextButtonVisible: true,
      };

    case IM_SET_INFO_REQUEST:
      return {
        ...state,
        im_info_request: action.payload.value,
        isNextButtonVisible: true,
      };
    case IM_SET_APP_IDENTITY:
      return {
        ...state,
        im_app_identity: action.payload.value,
        isNextButtonVisible: true,
      };
    case SEND_FORM_IM_SUCCESS:
      return {
        ...state,
        pageIndex: ++action.payload.value,
      };
    case SEND_FORM_IM_FAILED: 
      return {
        ...state,
        pageIndex: 7,
      };
    default:
      return state;
  }
};
