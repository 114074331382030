import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Col, Row, Image } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";
import RadioGroup from "../Default/RadioGroup";
import { PVWording } from "../DataForForm/PVWording";

function PersonWithSideEffect({
  personWithSideEffect,
  setPersonWithSideEffect,
  clickNextButton,
  clickPrevButton,
  pageIndex,
  appProOrNotpro,
}) {
  //HOOK FORM
  const { register, handleSubmit } = useForm({
    defaultValues: personWithSideEffect,
  });

  const ageGroup = PVWording.pro.age_group;

  // Page Title

  const titlePage =
    appProOrNotpro === "Patient" ? (
      <p className="question-label row-no-margin">
        Qui est concerné par la survenue d’un effet indésirable?
      </p>
    ) : (
      <p className="question-label row-no-margin">
        Qui est concerné par la déclaration de pharmacovigilance?
      </p>
    );

  const onSubmit = (data) => {
    let sendData = Object.assign({ ...data });
    setPersonWithSideEffect(sendData);
    clickNextButton(pageIndex);
  };
  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right" onClick={() => onSubmit}>
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <Row className="justify-content-start ml-5">
      <Col xs="11" sm="11">
        {titlePage}
      </Col>
      <Col xs="10" md="8" lg="5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="10">
              <label className="contact-form__label">Prénom</label>
              <input
                autoComplete="off"
                className="contact-form__field"
                name="pvi_pv_prenom"
                placeholder="Première lettre"
                defaultValue={personWithSideEffect.pv_pvi_prenom}
                maxLength="1"
                ref={register({ required: false, maxLength: 1 })}
              ></input>
            </Col>
            <Col md="10">
              <label className="contact-form__label">Nom</label>
              <input
                autoComplete="off"
                className="contact-form__field"
                name="pvi_pv_nom"
                placeholder="3 premières lettres"
                defaultValue={personWithSideEffect.pv_pvi_nom}
                maxLength="3"
                ref={register({ required: false })}
              ></input>
            </Col>
            <Col md="10">
              <label className="contact-form__label">Sexe</label>
              <select
                className="contact-form__field"
                name="pvi_pv_sexe"
                defaultValue={personWithSideEffect.pv_pvi_sexe}
                ref={register({ required: false })}
              >
                <option value=""></option>
                <option value="H">M</option>
                <option value="F">F</option>
              </select>
            </Col>
            <Col md="10">
              <label className="contact-form__label">Date de naissance</label>
              <input
                autoComplete="off"
                className="contact-form__field"
                type="date"
                name="pvi_pv_date_naissance"
                defaultValue={personWithSideEffect.pvi_pv_date_naissance}
                ref={register({ required: false })}
              />
            </Col>
            {appProOrNotpro !== "Patient" && (
              <Fragment>
                <Col md="5">
                  <label className="contact-form__label">Poids</label>
                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    name="pvi_pv_poids"
                    placeholder="kg"
                    defaultValue={personWithSideEffect.pv_pvi_poids}
                    ref={register({ required: false })}
                  ></input>
                </Col>
                <Col md="5">
                  <label className="contact-form__label">Taille</label>
                  <input
                    autoComplete="off"
                    className="contact-form__field"
                    name="pvi_pv_taille"
                    placeholder="cm"
                    defaultValue={personWithSideEffect.pv_pvi_taille}
                    ref={register({ required: false })}
                  ></input>
                </Col>
                <Col md="12">
                  <label>Groupe d'âge</label>
                </Col>
                <Col>
                  <RadioGroup
                    name="pvi_pv_groupe_age"
                    reference={register({ required: false })}
                    items={ageGroup}
                  ></RadioGroup>
                </Col>
              </Fragment>
            )}
          </Row>
          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start">
                  <div className="ml-1">
                    <button className="no-style-button" type="submit">
                      {nextButton}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">{prevButton}</div>
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="submit"
              >
                {nextButton}
              </button>
            </Row>
          </div>
        </form>
      </Col>
      <Col xs="10" md="4">
        <Image></Image>
      </Col>
    </Row>
  );
}

export default PersonWithSideEffect;
