export const PVWording = {
  grand_public: {
    question: {
      side_effects_description:
        "Pouvez-vous décrire l’(les) effet(s) indésirable(s) rapporté(s)?​",
    },
    ongoingTreatment: {
      drugIndication: "Motif d'utilisation du produit",
    },
  },
  pro: {
    question: {
      side_effects_description:
        "Pouvez-vous décrire l’(les) effet(s) indésirable(s) et/ou la situation de pharmacovigilance rapporté(s)?",
    },
    ongoingTreatment: {
      drugIndication: "Indication",
    },
    age_group: [
      {
        id: "Nouveau né",
        label: "Nouveau-né",
      },
      {
        id: "Nourrisson",
        label: "Nourrisson",
      },
      {
        id: "Enfant",
        label: "Enfant",
      },
      {
        id: "Adolescent",
        label: "Adolescent",
      },
      {
        id: "Adulte",
        label: "Adulte",
      },
      {
        id: "Personne âgée",
        label: "Personne âgée",
      },
    ],
    pvi_pv_statut: [
      {
        id: "En cours",
        label: "En cours",
      },
      {
        id: "Terminé",
        label: "Terminé",
      },
    ],
    declararation_concerns: [
      {
        id: "Un(des) effets indésirables",
        label: "Un(des) effet(s) indésirable(s)",
      },
      {
        id: "Utilisation hors AMM",
        label: "Utilisation hors AMM",
      },
      {
        id: "Exposition pendant la grossesse",
        label: "Exposition durant la grossesse (maternelle ou via le sperme)",
      },
      {
        id: "Manque d'efficacité",
        label: "Manque d'efficacité partielle ou totale",
      },
      {
        id: "Exposition pendant l’allaitement",
        label: "Exposition durant l’allaitement",
      },
      {
        id: "Surdosage",
        label: "Surdosage",
      },
      {
        id: "Exposition professionnelle",
        label: "Exposition professionnelle",
      },
      {
        id: "Mésusage",
        label: "Mésusage ou usage détourné",
      },
      {
        id: "Erreur médicamenteuse",
        label: "Erreur médicamenteuse / Risque d'erreur médicamenteuse",
      },
      {
        id: "Abus",
        label: "Abus",
      },
      {
        id: "Effet bénéfique inattendu",
        label: "Effet bénéfique inattendu",
      },
    ],
    description_of_adverse_reaction: [
      {
        id: "effet relié au médicament",
        label: "Effet relié au produit",
      },
      {
        id: "effet non relié au médicament",
        label: "Effet non relié au produit",
      },
      {
        id: "NSP",
        lable: "Ne sait pas",
      },
      {
        id: "NA",
        lable: "Non applicable",
      },
    ],
    pvi_pv_treatment: [
      {
        id: "OUI",
        label: "Oui",
      },
      {
        id: "NON",
        label: "Non",
      },
      {
        id: "UNK",
        label: "Ne sait pas",
      },
    ],
    pvi_pv_after_side_effect: [
      {
        id: "Guéri",
        label: "Guéri",
      },
      {
        id: "UNK",
        label: "Ne sait pas",
      },
      {
        id: "Guéri avec séquelles",
        label: "Guéri avec séquelles",
      },
      {
        id: "NA",
        label: "Non Applicable",
      },
      {
        id: "En cours de guérison",
        label: "En cours de guérison",
      },
      {
        id: "Décès",
        label: "Décès",
      },
      {
        id: "Non guéri",
        label: "Non guéri",
      },
    ],
    pvi_pv_causality: [
      {
        id: "Effet relié au médicament",
        label: "Effet relié au produit",
      },
      {
        id: "Effet non relié au médicament",
        label: "Effet non relié au produit",
      },
      {
        id: "NSP",
        label: "Ne sait pas",
      },
      {
        id: "NA",
        label: "Non Applicable",
      },
    ],
    pvi_drug_stopped: [
      { id: "OUI", label: "Oui" },
      { id: "NON", label: "Non" },
      { id: "UNK", label: "Ne sait pas" },
      { id: "NA", label: "Non Applicable" },
    ],
    pvi_drug_reintroduced: [
      { id: "OUI", label: "Oui" },
      { id: "NON", label: "Non" },
      { id: "UNK", label: "Ne sait pas" },
      { id: "NA", label: "Non Applicable" },
    ],
    pvi_recurence_side_effect: [
      { id: "Récidive", label: "Oui" },
      { id: "Non récidive", label: "Non" },
      { id: "NA", label: "Ne sait pas" },
    ],
    pv_professionnel_details: [
      { id: 0, label: "Oui" },
      { id: 1, label: "Non" },
    ],
    pvi_pv_following_substitution: [
      { id: "OUI", label: "Oui" },
      { id: "NON", label: "Non" },
      { id: "NSP", label: "Ne sait pas" },
      { id: "NA", label: "Non applicable" },
    ],
    pvi_pv_substitution_type: [
      { id: "Princeps", label: "Du princeps" },
      { id: "Generic", label: "D'un autre générique" },
    ],
    pvi_pv_side_effect_patient: [
      { id: "OUI", label: "Oui" },
      { id: "NON", label: "Non" },
      { id: "NSP", label: "Ne sait pas" },
    ],
    pvi_pv_gravity: [
      { id: "Non grave", label: "Non grave" },
      { id: "Grave", label: "Grave" },
    ],
    pvi_pv_result: [
      { id: "Incapacité, invalidité", label: "Incapacité, invalidité" },
      {
        id: "Susceptible de mettre la vie en danger",
        label: "Susceptible de mettre la vie en danger",
      },
      {
        id: "Anomalie congénitale",
        label: "Anomalie congénitale",
      },
      {
        id: "Hospitalisation",
        label: "Hospitalisation ou prolongation d’hospitalisation",
      },
      {
        id: "situation médicale significative",
        label: "situation médicale significative",
        tooltip:
          "Qui aurait pu entrainer l’une des situations ci-dessus en l’absence de prise en charge",
      },
      {
        id: "Décès",
        label: "Décès",
      },
    ],
    pvi_pv_cause_death: [
      { id: "OUI", label: "Oui" },
      { id: "NON", label: "Non" },
      { id: "NSP", label: "Ne sait pas" },
    ],
    pvi_pv_accept: [
      { id: "OUI", label: "OUI" },
      { id: "NON", label: "NON" },
    ],
    pvi_pv_examen: [
      { id: "OUI", label: "OUI" },
      { id: "NON", label: "NON" },
    ],
    pvi_pv_hospitalisation: [
      { id: "OUI", label: "OUI" },
      { id: "NON", label: "NON" },
    ],
    pvi_voie_administration: [
      {
        value: "Orale",
        label: "Orale",
      },
      {
        value: "Sublinguale (sous la langue)",
        label: "Sublinguale (sous la langue)",
      },
      {
        value: "Ophtalmique (dans l’œil)",
        label: "Ophtalmique (dans l’œil)",
      },
      {
        value: "Nasale (dans le nez)",
        label: "Nasale (dans le nez)",
      },
      {
        value: "Respiratoire (par inhalation)",
        label: "Respiratoire (par inhalation)",
      },
      {
        value: "Auriculaire (dans l’oreille)",
        label: "Auriculaire (dans l’oreille)",
      },
      {
        value: "Rectale (suppositoire)",
        label: "Rectale (suppositoire)",
      },
      {
        value: "Vaginale",
        label: "Vaginale",
      },
      {
        value: "Injection (piqûre)",
        label: "Injection (piqûre)",
      },
      {
        value: "Injection intra-musculaire (dans le muscle)",
        label: "Injection intra-musculaire (dans le muscle)",
      },
      {
        value: "Injection intra-veineuse (dans une veine)",
        label: "Injection intra-veineuse (dans une veine)",
      },
      {
        value: "Injection sous-cutanée (sous la peu)",
        label: "Injection sous-cutanée (sous la peu)",
      },
      {
        value: "Injection intra-dermique",
        label: "Injection intra-dermique",
      },
      {
        value: "Autre",
        label: "Autre",
      },
    ],
  },
};
