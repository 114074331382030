import React from "react";
import { FirstAidKit, HeartRate, Meds } from "../Default/Icons";
export const HomeWording = {
  grand_public: {
    home_problem_block: [
      {
        id: "PV",
        title:
          "Je souhaite rapporter un effet indésirable survenu suite à la prise d’un produit",
        icon: <HeartRate />,
        tooltip:
          "Il peut s’agir d’un effet indésirable et/ou d’une situation particulière (prise pendant la grossesse, erreur médicamenteuse, effet bénéfique …)​",
      },
      {
        id: "AQ",
        title: "Il y a un défaut sur le produit et/ou son emballage",
        icon: <Meds />,
      },
      {
        id: "IM",
        title: "J'ai une question",
        icon: <FirstAidKit />,
      },
    ],
  },
  pro: {
    home_problem_block_pro: [
      {
        id: "PV",
        title: "Je souhaite déclarer un cas de pharmacovigilance",
        icon: <HeartRate />,
        tooltip:
          "Il peut s’agir d’un effet indésirable et/ou d’une situation particulière (prise pendant la grossesse, erreur médicamenteuse, effet bénéfique …)​",
      },
      {
        id: "AQ",
        title: "Je souhaite faire une réclamation qualité",
        icon: <Meds />,
      },
      {
        id: "IM",
        title: "J'ai une question d'information médicale et/ou pharmaceutique",
        icon: <FirstAidKit />,
      },
    ],
  },
};
