import React, { Fragment, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import RemoveButton from "../button/RemoveButton";
import { Row, Col } from "react-bootstrap";
function NestedField({ nestIndex, control, register, errors }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `drugConcerned[${nestIndex}].lot`,
  });
  useEffect(() => {
    if (fields.length === 0) {
      append({ pvi_rqp_lot: "", pvi_rqp_peremption: "" });
    }
  });

  const formatDate = (e) => {
    if (e.target.value.length > 2 && !e.target.value.includes("/")) {
      let newValue =
        e.target.value.substring(0, 2) +
        "/" +
        e.target.value.substring(2, e.target.value.length);
      e.target.value = newValue;
    }
  };

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <Fragment key={item.id}>
            <hr />

            {fields.length > 1 && (
              <RemoveButton
                className="cancel-button  "
                clickRemoveButton={() => remove(index)}
              ></RemoveButton>
            )}
            <label className="contact-form__label">N° de lot </label>

            <input
              autoComplete="off"
              className="contact-form__field"
              name={`drugConcerned[${nestIndex}].lot[${index}].pvi_rqp_lot`}
              defaultValue={item.pvi_rqp_lot}
              ref={register()}
            />
            {/* errors will return when field validation fails  */}
            {errors?.drugConcerned?.[nestIndex]?.lot?.[index]?.pvi_rqp_lot
              ?.type === "maxLength" && (
              <span className="mandatory-field">
                La taille ne doit pas dépasser 255 caractères
              </span>
            )}
            <label className="contact-form__label">Date de péremption </label>
            <input
              autoComplete="off"
              className="contact-form__field"
              name={`drugConcerned[${nestIndex}].lot[${index}].pvi_rqp_peremption`}
              placeholder="MM/AAAA"
              defaultValue={item.pvi_rqp_peremption}
              ref={register({
                pattern: {
                  value: /^(0[1-9]|1[0-2])\/?(20[0-9]{2})$/,
                  message: "Format de la date invalide ex: 01/2020",
                },
                maxLength: {
                  value: 255,
                  message: "Ne doit pas exceder 255 caracteres",
                },
              })}
              onChange={(e) => {
                formatDate(e);
              }}
              maxLength={7}
            />
            {/* errors will return when field validation fails  */}
            {errors?.drugConcerned?.[nestIndex]?.lot?.[index]
              ?.pvi_rqp_peremption?.message && (
              <span className="mandatory-field">
                {
                  errors?.drugConcerned?.[nestIndex]?.lot?.[index]
                    ?.pvi_rqp_peremption?.message
                }
              </span>
            )}
          </Fragment>
        );
      })}
      <Row>
        <Col md="12" className="my-auto">
          <div
            className="app-form-button text-center d-inline"
            onClick={() => append({ pvi_rqp_lot: "", pvi_rqp_peremption: "" })}
          >
            Ajouter un lot
          </div>
        </Col>
      </Row>
      <hr />
    </div>
  );
}
export default NestedField;
