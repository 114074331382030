import React from "react";
import { wording } from "../DataForForm/Wording";
import FormBlock from "../Default/FormBlock";

function OriginBlock({ parentCallback, selectedValue, appProOrNotpro }) {
  const grandPublic = wording.grand_public.origin;
  const pro = wording.pro.origin;
  const items = appProOrNotpro !== "Patient" ? pro : grandPublic;

  return (
    <FormBlock
      parentCallback={parentCallback}
      selectedValue={selectedValue}
      items={items}
    ></FormBlock>
  );
}

export default OriginBlock;
