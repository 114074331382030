import history from "./../history";

/**
 * Hander of change route from home to other
 * @param {*} routeName
 * @param {*} pageIndex
 * @param {*} min
 * @param {*} max
 */
export function fromHomeToOtherHandler(routeName, pageIndex, offside) {
  if (routeName && ++pageIndex > offside) {
    history.push(routeName.toLowerCase());
  }
}

/**
 * fromOtherToHomeHandler
 * @param {*} pageIndex
 * @param {*} offside
 */
export function fromOtherToHomeHandler(pageIndex, offside) {
  if (pageIndex === offside) {
    history.push("/");
  }
}

/**
 * check checkStateValuesForNextBtn for AQ form
 * @param {*} state
 */
export function checkStateValuesForNextBtn(state) {
  let nextBtn = false;
  let pageIndex = state.pageIndex + 1;
  if (pageIndex === 4 && state.pvi_rqp_reclamation) {
    nextBtn = true;
  }
  if (pageIndex === 5 && state.pvi_rqp_reclamation2) {
    nextBtn = true;
  }
  if (pageIndex === 6 && state.pvi_rqp_reclamation3) {
    nextBtn = true;
  }
  if (pageIndex === 7 && state.pvi_rqp_reclamation4) {
    nextBtn = true;
  }
  if (pageIndex === 8 && state.pvi_rqp_reclamation5) {
    nextBtn = true;
  }
  if (pageIndex === 9 && state.pvi_rqp_decouvert) {
    nextBtn = true;
  }
  if (pageIndex === 10 && state.pvi_rqp_quand) {
    nextBtn = true;
  }

  return nextBtn;
}
