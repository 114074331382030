import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
function ProblemBlockModal({ show, onHide }) {
  const nextButton = (
    <React.Fragment>
      <div onClick={onHide}>
        <div className="d-inline">CONTINUER</div>
      </div>
    </React.Fragment>
  );
  return (
    <Modal
      className="app-modal--description"
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
    >
      <p
        className="question-label pt-4 pb-3 pl-3 pr-3"
        style={{ textAlign: "center", color: "grey", fontSize: "18px" }}
      >
        Nous vous informons que tous les champs qui vont suivre ne sont pas
        obligatoires, merci de compléter le maximum d’informations.
      </p>

      {/* Only desktop*/}
      <div className="d-none d-sm-block">
        <Row className="justify-content-center pt-2 mt-2 ">
          <Col sm="5">
            <Row className="justify-content-center ml-1">
              <button className="button-modal">{nextButton}</button>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Only mobile*/}
      <div className="d-sm-none d-block">
        <Row className="justify-content-center ml-5 mr-5">
          <button className="d-block button-modal">
            {nextButton}
          </button>
        </Row>
      </div>
    </Modal>
  );
}

export default ProblemBlockModal;
