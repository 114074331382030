import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import UserBlock from "../components/Home/UserBlock";
import ProblemBlock from "../components/Home/ProblemBlock";
import PrevButton from "./../components/button/PrevButton";
import NextButton from "./../components/button/NextButton";
import {
  setAppTpaId,
  clickNextButton,
  clickPrevButton,
  setPviType,
  setAppProOrNotPro,
} from "./../redux/actions/homeActions";
import HealthProfessionalChoice from "../components/Home/HealthProfessionalChoice";

class HomeConatiner extends Component {
  render() {
    const {
      home,
      setAppTpaId,
      setPviType,
      clickNextButton,
      clickPrevButton,
      setAppProOrNotPro,
    } = this.props;

    //components in home container page index 0-2
    const components = {
      0: (
        <UserBlock
          parentCallback={setAppProOrNotPro}
          selectedValue={home.app_pro_or_notpro}
        ></UserBlock>
      ),
      1: (
        <HealthProfessionalChoice
          parentCallback={setAppTpaId}
          selectedValue={home.app_tpa_id}
          appProOrNotpro={home.app_pro_or_notpro}
        ></HealthProfessionalChoice>
      ),
      2: (
        <ProblemBlock
          appProOrNotpro={home.app_pro_or_notpro}
          parentCallback={setPviType}
          selectedValue={home.pvi_type}
          clickNextButton={clickNextButton}
          pageIndex={home.pageIndex}
          clickPrevButton={clickPrevButton}
        ></ProblemBlock>
      ),
    };
    const pageIndexExculForBtn = [2];
    return (
      <React.Fragment>
        <Row className="home-container justify-content-center">
          {components[home.pageIndex]}
        </Row>

        {/* Only desktop*/}
        {!pageIndexExculForBtn.includes(home.pageIndex) && (
          <div className="d-none d-sm-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ">
              <Col sm="4" lg="3" className="mr-4 mt-2">
                <Row className="justify-content-end">
                  <PrevButton
                    isPrevButtonVisible={home.isPrevButtonVisible}
                    clickPrevButton={clickPrevButton}
                    pageIndex={home.pageIndex}
                  ></PrevButton>
                </Row>
              </Col>
              <Col sm="4" lg="3" className="ml-4 mt-2">
                <Row className="justify-content-start">
                  <NextButton
                    isNextButtonVisible={home.isNextButtonVisible}
                    clickNextButton={clickNextButton}
                    pageIndex={home.pageIndex}
                    isPv={home.pvi_type}
                  ></NextButton>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        {/* Only mobile*/}
        {!pageIndexExculForBtn.includes(home.pageIndex) && (
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-3 mt-3 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">
                <PrevButton
                  isPrevButtonVisible={home.isPrevButtonVisible}
                  clickPrevButton={clickPrevButton}
                  pageIndex={home.pageIndex}
                ></PrevButton>
              </div>
              <div className="d-block ml-5 mr-5 mt-3">
                <NextButton
                  isNextButtonVisible={home.isNextButtonVisible}
                  clickNextButton={clickNextButton}
                  pageIndex={home.pageIndex}
                ></NextButton>
              </div>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {
  setAppTpaId,
  setPviType,
  clickNextButton,
  clickPrevButton,
  setAppProOrNotPro,
})(HomeConatiner);
