import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "../../assets/scss/main.scss";
function DrugDescriptionModal({ setShowModal, clickNextButton, pageIndex }) {
  function validateModal() {
    setShowModal(false);
  }
  const nextButton = (
    <React.Fragment>
      <div onClick={() => validateModal(pageIndex)}>
        <div className="d-inline">CONTINUER</div>
      </div>
    </React.Fragment>
  );
  return (
    <Modal
      className="app-modal--description"
      show={true}
      backdrop="static"
      keyboard={false}
    >
      <p
        className="question-label pt-4 pb-3 pl-3 pr-3"
        style={{ textAlign: "center", color: "grey", fontSize: "18px" }}
      >
        Merci de garder le produit qui pourra vous être demandé dans le cadre de
        l'enquête qualité.
      </p>

      {/* Only desktop*/}
      <div className="d-none d-sm-block">
        <Row className="justify-content-center pt-2 mt-2 ">
          <Col sm="5">
            <Row className="justify-content-center ml-1">
              <button className="button-modal">{nextButton}</button>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Only mobile*/}
      <div className="d-sm-none d-block">
        <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
          <button className="d-block ml-5 mr-5 mt-3">{nextButton}</button>
        </Row>
      </div>
    </Modal>
  );
}

export default DrugDescriptionModal;
