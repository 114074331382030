import {
  PV_CLICK_NEXT_BTN,
  PV_CLICK_PREV_BTN,
  PV_SET_PERSON_WITH_SIDE_EFFECT,
  PV_SET_PHARMACOVIGILANCE_SITUATION,
  PV_SET_SIDE_EFFECTS_DESCRIPTION,
  PV_SET_DRUG_CONCERNED,
  PV_SET_SIDE_EFFECT_EVENT,
  PV_SET_APP_PRO_OR_NOPRO,
  PV_SET_PROFESSIONNEL_DETAILS,
  PV_SET_MEDICAL_BACKGROUND,
  PV_SET_GRAVITY,
  PV_SET_ONGOING_TREATMENT,
  PV_SET_EFFECT_FOLLOWING_SUBSTITUTION,
  PV_SET_DRUG_HISTORY,
  PV_SET_APP_IDENTITY,
  SEND_FORM_PV_SUCCESS,
  SEND_FORM_PV_FAILED,
} from "./actionTypes";
import { sendFormPvRequest } from "./../../utils/service";

/**
 * set page index value when next clicked
 * @param {*} pageIndex
 */
export function clickNextButton(pageIndex) {
  return {
    type: PV_CLICK_NEXT_BTN,
    payload: { pageIndex },
  };
}
/**
 * set page index value when prev clicked
 * @param {*} pageIndex
 */
export function clickPrevButton(pageIndex) {
  return {
    type: PV_CLICK_PREV_BTN,
    payload: { pageIndex },
  };
}
/**
 * setPersonWithSideEffect
 * @param {*} value
 */
export function setPersonWithSideEffect(value) {
  return {
    type: PV_SET_PERSON_WITH_SIDE_EFFECT,
    payload: { value },
  };
}
/**
 * setPharmacovigilanceSituation
 * @param {*} value
 */
export function setPharmacovigilanceSituation(value) {
  return {
    type: PV_SET_PHARMACOVIGILANCE_SITUATION,
    payload: { value },
  };
}
/**
 * setPvDrugConcerned
 * @param {*} value
 */
export function setPvDrugConcerned(value) {
  return {
    type: PV_SET_DRUG_CONCERNED,
    payload: { value },
  };
}
/**
 * setPharmacovigilanceSituation
 * @param {*} value
 */
export function setSideEffectDescription(value) {
  return {
    type: PV_SET_SIDE_EFFECTS_DESCRIPTION,
    payload: { value },
  };
}
/**
 * @param {*} value
 */
export function setPersonSideEffectEvent(value) {
  return {
    type: PV_SET_SIDE_EFFECT_EVENT,
    payload: { value },
  };
}
/**
 * setMedicalBackground
 * @param {*} value
 */
export function setMedicalBackground(value) {
  return {
    type: PV_SET_MEDICAL_BACKGROUND,
    payload: { value },
  };
}
/**
 * setOngoingTreatment
 * @param {*} value
 */
export function setOngoingTreatment(value) {
  return {
    type: PV_SET_ONGOING_TREATMENT,
    payload: { value },
  };
}
/**
 * setPvAppProOrNotPro
 * @param {*} value
 */
export function setPvAppProOrNotPro(value) {
  return {
    type: PV_SET_APP_PRO_OR_NOPRO,
    payload: { value },
  };
}
/**
 * setPvProfessionnelDetails
 * @param {*} value
 */
export function setPvProfessionnelDetails(value) {
  return {
    type: PV_SET_PROFESSIONNEL_DETAILS,
    payload: { value },
  };
}
/**
 * setPvEffectFollowingSubstitution
 * @param {*} value
 */
export function setPvEffectFollowingSubstitution(value) {
  return {
    type: PV_SET_EFFECT_FOLLOWING_SUBSTITUTION,
    payload: { value },
  };
}

/** setDrugHistory
 * @param {*} value
 */
export function setDrugHistory(value) {
  return {
    type: PV_SET_DRUG_HISTORY,
    payload: { value },
  };
}
/** setPvGravity
 * @param {*} value
 */
export function setPvGravity(value) {
  return {
    type: PV_SET_GRAVITY,
    payload: { value },
  };
}
/** setPvAppIdentity
 * @param {*} value
 */
export function setPvAppIdentity(value) {
  return {
    type: PV_SET_APP_IDENTITY,
    payload: { value },
  };
}
/** sendFormPVAction
 * @param {*} value
 */
export function sendFormPVAction(value) {
  return {
    type: SEND_FORM_PV_SUCCESS,
    payload: { value },
  };
}
/**
 * send Form PV failed
 * @param {*} value
 */
export function sendFormPVFailed(value) {
  return {
    type: SEND_FORM_PV_FAILED,
    payload: { value },
  };
}

export const sendFormPV = (formData, pageIndex) => (dispatch) => {
  sendFormPvRequest(formData)
    .then(async (res) => {
      await dispatch(sendFormPVAction(pageIndex));
    })
    .catch((err) => {
      dispatch(sendFormPVFailed(err));
    });
};
