import { fromOtherToHomeHandler } from "../../utils/function";
import {
  PV_CLICK_NEXT_BTN,
  PV_CLICK_PREV_BTN,
  PV_SET_PERSON_WITH_SIDE_EFFECT,
  PV_SET_PHARMACOVIGILANCE_SITUATION,
  PV_SET_SIDE_EFFECTS_DESCRIPTION,
  PV_SET_DRUG_CONCERNED,
  PV_SET_SIDE_EFFECT_EVENT,
  PV_SET_APP_PRO_OR_NOPRO,
  PV_SET_MEDICAL_BACKGROUND,
  PV_SET_ONGOING_TREATMENT,
  PV_SET_PROFESSIONNEL_DETAILS,
  PV_SET_EFFECT_FOLLOWING_SUBSTITUTION,
  PV_SET_DRUG_HISTORY,
  PV_SET_GRAVITY,
  PV_SET_APP_IDENTITY,
  SEND_FORM_PV_SUCCESS,
  SEND_FORM_PV_FAILED,
} from "../actions/actionTypes";

const initState = {
  pageIndex: 3,
  step: 1,
  isNextButtonVisible: false,
  isPrevButtonVisible: true,
  app_user: "",
  pv_app_pro_or_notpro: "",
  pv_person_with_side_effect: {},
  pv_pharmacovigilance_situation: {},
  pv_side_effect_description: {},
  pv_medical_background: {},
  pv_ongoing_treatment: {},
  pv_side_effect_following_substitution: {},
  pv_drug_concerned: [],
  pv_side_effect_event: [],
  pv_professionnel_details: {},
  pv_drug_history: [],
  pv_gravity: {},
  app_identity: {},
};

export const pvReducer = (state = initState, action) => {
  switch (action.type) {
    case PV_CLICK_PREV_BTN:
      //change route
      fromOtherToHomeHandler(action.payload.pageIndex, 3);
      if (state.pv_app_pro_or_notpro === "Patient" && state.pageIndex === 6) {
        --action.payload.pageIndex;
      }
      if (state.pv_app_pro_or_notpro === "Patient" && state.pageIndex === 9) {
        --action.payload.pageIndex;
      }
      if (state.pv_app_pro_or_notpro === "Patient" && state.pageIndex === 10) {
        action.payload.pageIndex -= 2;
      }
      if (state.pv_app_pro_or_notpro !== "Patient" && state.pageIndex === 14) {
        --action.payload.pageIndex;
      }
      let prevResult = {
        ...state,
        step: state.step - 1,
        pageIndex:
          state.pageIndex > 3 ? --action.payload.pageIndex : state.pageIndex,
        isPrevButtonVisible: true,
        isNextButtonVisible: true,
      };
      return prevResult;
    case PV_CLICK_NEXT_BTN:
      if (state.pv_app_pro_or_notpro === "Patient" && state.pageIndex === 4) {
        ++action.payload.pageIndex;
      }
      if (state.pv_app_pro_or_notpro === "Patient" && state.pageIndex === 7) {
        action.payload.pageIndex += 2;
      }
      if (state.pv_app_pro_or_notpro === "Patient" && state.pageIndex === 8) {
        ++action.payload.pageIndex;
      }
      if (state.pv_app_pro_or_notpro !== "Patient" && state.pageIndex === 12) {
        ++action.payload.pageIndex;
      }
      let nextResult = {
        ...state,
        step: state.step + 1,
        pageIndex: ++action.payload.pageIndex,
        isNextButtonVisible: false,
        isPrevButtonVisible: true,
      };
      return nextResult;
    case PV_SET_APP_PRO_OR_NOPRO:
      return {
        ...state,
        pv_app_pro_or_notpro: action.payload.value,
      };
    case PV_SET_PERSON_WITH_SIDE_EFFECT:
      return {
        ...state,
        pv_person_with_side_effect: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_PHARMACOVIGILANCE_SITUATION:
      return {
        ...state,
        pv_pharmacovigilance_situation: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_SIDE_EFFECTS_DESCRIPTION:
      return {
        ...state,
        pv_side_effect_description: action.payload.value,
      };
    case PV_SET_DRUG_CONCERNED:
      return {
        ...state,
        pv_drug_concerned: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_SIDE_EFFECT_EVENT:
      return {
        ...state,
        pv_side_effect_event: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_PROFESSIONNEL_DETAILS:
      return {
        ...state,
        pv_professionnel_details: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_MEDICAL_BACKGROUND:
      return {
        ...state,
        pv_medical_background: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_GRAVITY:
      return {
        ...state,
        pv_gravity: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_ONGOING_TREATMENT:
      return {
        ...state,
        pv_ongoing_treatment: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_EFFECT_FOLLOWING_SUBSTITUTION:
      return {
        ...state,
        pv_side_effect_following_substitution: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_DRUG_HISTORY:
      return {
        ...state,
        pv_drug_history: action.payload.value,
        isNextButtonVisible: true,
      };
    case PV_SET_APP_IDENTITY:
      return {
        ...state,
        app_identity: action.payload.value,
        isNextButtonVisible: true,
      };
    case SEND_FORM_PV_SUCCESS:
      return {
        ...state,
        pageIndex: ++action.payload.value,
      };
      case SEND_FORM_PV_FAILED:
        return {
          ...state,
          pageIndex: 16,
        };
    default:
      return state;
  }
};
