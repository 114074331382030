import React from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { LeftArrow, RightArrow } from "../../components/Default/Icons";

function CommentBlock({
  parentCallback,
  comment,
  clickNextButton,
  clickPrevButton,
  pageIndex,
  pviType,
}) {
  // HOOK FORM
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    parentCallback(data.pvi_rqp_reclamation);
    clickNextButton(pageIndex);
  };

  const prevButton = (
    <React.Fragment>
      <div
        className="app-button text-left"
        onClick={() => clickPrevButton(pageIndex)}
      >
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <LeftArrow></LeftArrow>
        </div>
        <div className="d-inline">RETOUR</div>
      </div>
    </React.Fragment>
  );

  const nextButton = (
    <React.Fragment>
      <div className="app-button text-right">
        <div className="d-inline">CONTINUER</div>
        <div className="d-inline mr-3 ml-3 mb-5 ">
          <RightArrow></RightArrow>
        </div>
      </div>
    </React.Fragment>
  );
  const placeholder =
    pviType === "AQ"
      ? "Vous pouvez écrire vos commentaires dans cet espace *"
      : " Vous pouvez écrire vos commentaires dans cet espace";
  return (
    <Row className="row-no-margin justify-content-center">
      <Col sm="9">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* register your input into the hook by invoking the "register" function */}
          <textarea
            style={{ height: "200px" }}
            className="contact-form__field"
            name="pvi_rqp_reclamation"
            placeholder={placeholder}
            defaultValue={comment}
            maxLength={300}
            ref={register({ required: true, maxLength: 300 })}
          />
          {/* errors will return when field validation fails  */}
          {errors.pvi_rqp_reclamation && (
            <span className="mandatory-field">Ce champ est obligatoire *</span>
          )}
          {errors.pvi_rqp_reclamation &&
            errors.pvi_rqp_reclamation.type === "maxLength" && (
              <span>La taille ne doit pas dépasser 300 caractères</span>
            )}
          {/* Only desktop*/}
          <div className="d-none d-sm-block">
            <Row className="justify-content-start pt-2 mt-2 ">
              <Col sm="6">
                <Row className="justify-content-end"> {prevButton}</Row>
              </Col>
              <Col sm="5">
                <Row className="justify-content-start ml-1">
                  <button className="no-style-button" type="submit">
                    {nextButton}
                  </button>
                </Row>
              </Col>
            </Row>
          </div>
          {/* Only mobile*/}
          <div className="d-sm-none d-block">
            <Row className="justify-content-center pt-5 mt-5 pb-5 mb-5 ml-5 mr-5">
              <div className="d-block ml-5 mr-5">{prevButton}</div>
              <button
                className="no-style-button d-block ml-5 mr-5 mt-3"
                type="submit"
              >
                {nextButton}
              </button>
            </Row>
          </div>
        </form>
      </Col>
    </Row>
  );
}

export default CommentBlock;
